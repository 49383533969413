import React, { useEffect, useState } from "react";
import { sortItems, sortedRoles } from "../../../helpers";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import ButtonAction from "../../Button/ButtonAction";
import Table from "../../Table/Table";
import CheckboxField from "../../CheckboxField/CheckboxField";
import UserChip from "../../UserChip/UserChip";
import AddUsersPopup from "../AddUsersPopup/AddUsersPopup";
import Loader from "../../Loader/Loader";
import InputField from "../../InputField/InputField";
import Popup from "../../Popup/Popup";
import UsersListFilters from "./UsersListFilters";
import Tag from "../../Tag/Tag";
import "./UsersList.scss";

const UsersList = ({ loading, alias: propAlias, users: propUsers, markets: propMarkets, departments: propDepartments, updateUser, editable, deleteUser: propDeleteUser, selectedUsers, addContacts, deleteSelectedUsers }) => {
    const [open, setOpen] = useState(false);
    const [users, setUsers] = useState({});
    const [markets, setMarkets] = useState({});
    const [departments, setDepartments] = useState({});
    const [allSelected, setAllSelected] = useState(false);
    const [filters, setFilters] = useState({ incomplete: false, hasFilter: false, users: {}, role: sortedRoles.reduce((prev, curr) => ({ ...prev, [curr.name]: curr }), {}), market: {}, department: {} });
    const [deleteUser, setDeleteUser] = useState(null);

    useEffect(() => {
        setUsers(propUsers);
        setFilters(f => filterUsers({ ...f, users: propUsers }, propUsers, 'incomplete', f.incomplete));
    }, [propUsers]);

    useEffect(() => {
        setFilters(f => ({
            ...f,
            role: Object.entries(propAlias).reduce((prev, [currKey, currValue]) => ({
                ...prev,
                [currKey]: { id: currKey, name: currValue.alias || currValue.name }
            }), {})
        }));
    }, [propAlias]);

    useEffect(() => {
        const m = propMarkets.reduce((prev, curr) => ({ ...prev, [curr.id]: { name: curr.name, id: curr.id } }), {});
        setFilters(f => ({ ...f, market: m }));
        setMarkets(m);
    }, [propMarkets]);

    useEffect(() => {
        const d = propDepartments.reduce((prev, curr) => ({ ...prev, [curr.id]: { name: curr.name, id: curr.id } }), {});
        setFilters(f => ({ ...f, department: d }));
        setDepartments(d);
    }, [propDepartments]);

    useEffect(() => {
        const usersArray = Object.values(filters.users);
        for (let i = 0; i < usersArray.length; i++) {
            const user = usersArray[i];
            if (!users[user.id].selected) {
                setAllSelected(false);
                return;
            }
        };
        setAllSelected(true);
    }, [filters, users]);

    const selectOrUnselectAll = () => {
        const newUsers = { ...users, ...Object.values({ ...filters.users }).reduce((prevUsers, currUser) => ({ ...prevUsers, [currUser.id]: { ...currUser, selected: !allSelected } }), {}) };
        setUsers(newUsers);
        selectedUsers(newUsers);
    }

    const selectUser = user => {
        const newUsers = { ...users, [user.id]: { ...user, selected: !users[user.id].selected } }
        setUsers(newUsers);
        selectedUsers(newUsers);
    }

    const filterUsers = (filters, users, key, value) => {
        let newFilters = { ...filters };
        if (key !== 'incomplete') {
            newFilters = { ...filters, [key]: { ...filters[key], [value.id || value.name]: { ...value, selected: filters[key][value.id || value.name].selected ? false : true } } }
        } else {
            newFilters = { ...filters, incomplete: value };
        }
        let newFilteredUsers = { ...users };
        const filterRoles = Object.values(newFilters.role).filter(role => role.selected);
        const roleLength = filterRoles.length;
        const filterMarkets = Object.values(newFilters.market).filter(market => market.selected);
        const marketLength = filterMarkets.length;
        const filterDepartments = Object.values(newFilters.department).filter(department => department.selected);
        const departmentLength = filterDepartments.length;
        let foundFilter = false;

        if (roleLength !== 0 ||
            marketLength !== 0 ||
            departmentLength !== 0 ||
            !!newFilters.incomplete) {
            newFilteredUsers = Object.values(newFilteredUsers).reduce((prevUsers, currUser) => {
                let keep = true;
                if (roleLength > 0 && filterRoles.findIndex(role => role.id === currUser.role.id) === -1) {
                    keep = false;
                }
                if (marketLength > 0 && filterMarkets.findIndex(market => market.name === currUser.market.name) === -1) {
                    keep = false;
                }
                if (departmentLength > 0 && filterDepartments.findIndex(department => department.name === currUser.department.name) === -1) {
                    keep = false;
                }

                if (keep && !!newFilters.incomplete) {
                    keep = !currUser.role || !currUser.department || !currUser.market;
                }
                return {
                    ...prevUsers,
                    ...(keep ? { [currUser.id]: currUser } : {})
                }
            }, {});
            foundFilter = roleLength !== 0 ||
                marketLength !== 0 ||
                departmentLength !== 0;
        }
        return { ...newFilters, users: newFilteredUsers, hasFilter: foundFilter };
    }

    const handleFilter = (key, value) => {
        setFilters(filterUsers({ ...filters }, users, key, value));
    }

    const handleChangeUsers = (user, key, value) => {
        let newUser = {};

        switch (true) {
            case (key === 'editMode'):
                updateUser({
                    ...user,
                    editMode: value,
                    firstnameTemp: user.firstname,
                    lastnameTemp: user.lastname,
                    emailTemp: user.email
                })
                break;
            case (key === 'updateUser' && value === true):
                updateUser({
                    ...user,
                    firstname: user.firstnameTemp,
                    lastname: user.lastnameTemp,
                    email: user.emailTemp,
                    editMode: false
                });
                break;
            case key === 'market':
                updateUser({ ...user, market: { ...user.market, ...value } }, 'market', user.market, value);
                break;
            case key === 'department':
                updateUser({ ...user, department: { ...user.department, ...value } }, 'department', user.department, value);
                break;
            case key === 'role' || key === 'permission':
                newUser = { ...user, [key]: value };
                updateUser(newUser, key);
                break;
            default:
                updateUser({ ...user, [key]: value });
                break;
        }
    };

    return (
        <>
            <Grid>
                <GridColumn size={12} noPadding={true}>
                    <div className="UsersList__top--left" style={{ zIndex: 999 }}>
                        <UsersListFilters filters={filters} handleFilter={handleFilter} />
                    </div>
                    <div className="UsersList__top--right">
                        <div className="UsersList__top--buttons">
                            <ButtonAction type="primary" text="+ Add recipients" onClick={() => setOpen(true)} />
                            {Object.values(users).filter(user => user.selected).length > 0 &&
                                <ButtonAction
                                    type="delete"
                                    text={` (${Object.values(users).filter(user => user.selected).length})`}
                                    onClick={() => deleteSelectedUsers(Object.values(users).filter(user => user.selected).reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}))}
                                />
                            }
                        </div>
                    </div>
                </GridColumn>
                {filters.hasFilter && (
                    <GridColumn size={12} noPadding={true}>
                        {Object.values(filters.role).filter(role => role.selected).map(filter => (
                            <Tag tag={filter} onClick={value => handleFilter('role', value)} />
                        ))}
                        {Object.values(filters.market).filter(market => market.selected).map(filter => (
                            <Tag tag={filter} onClick={value => handleFilter('market', value)} />
                        ))}
                        {Object.values(filters.department).filter(department => department.selected).map(filter => (
                            <Tag tag={filter} onClick={value => handleFilter('department', value)} />
                        ))}
                    </GridColumn>
                )}
                <GridColumn size={12} noPadding={true}>
                    <Grid gridRowGap={0}>
                        <GridColumn size={12} noPadding={true}>
                            <div className="UsersList__list">
                                {!!loading && (
                                    <div className="UsersList__loader">
                                        <Loader />
                                    </div>
                                )}
                                <Table
                                    headers={[
                                        { key: 'select', name: <CheckboxField id={'all'} onChange={selectOrUnselectAll} checked={allSelected} />, width: '0' },
                                        { key: 'name', name: 'User' },
                                        { key: 'role', name: 'Role' },
                                        { key: 'market', name: 'Market' },
                                        ...(Object.values(departments).length > 0 ? [{ key: 'department', name: 'Department' }] : []),
                                        ...(editable ? [
                                            { key: 'permission', name: 'Permission' },
                                            { key: 'actions', name: '', width: 0 }
                                        ] : [])
                                    ]}
                                    data={Object.values(filters.users).sort((userA, userB) => sortItems(null, 'desc', userA.firstname + userA.lastname, userB.firstname + userB.lastname)).map(user => {
                                        return (
                                            {
                                                id: user.id,
                                                columns: !user.editMode ? [
                                                    { id: 'selected', by: (users[user.id].selected !== undefined && users[user.id].selected) || false, value: users[user.id].selected, type: 'checkbox', onChange: () => selectUser(user), user },
                                                    {
                                                        id: 'name', by: `${user.firstname} ${user.lastname}`,
                                                        label: <UserChip user={{ name: `${user.firstname ? user.firstname : ''} ${user.lastname ? user.lastname : ''}`, email: user.email }} onClick={() => handleChangeUsers(user, 'editMode', true)} />
                                                    },
                                                    {
                                                        id: 'role', by: user.role && user.role.name, label: 'Role', color: 'gray-5', selected: user.role && (user.role.alias || user.role.name), type: 'dropdown', options: (propAlias && Object.values(propAlias).map(a => ({ ...a, name: a.alias || a.name, default: a.name }))) || [],
                                                        onChange: value => handleChangeUsers(user, 'role', value)
                                                    },
                                                    {
                                                        id: 'market', by: (user.market && user.market.name !== 'NO MARKET') ? user.market.name : '-', label: 'Market', selected: (user.market && user.market.name !== 'NO MARKET') && user.market.name, type: 'dropdown', color: 'gray-5', options: Object.values(markets),
                                                        onChange: value => handleChangeUsers(user, 'market', value)
                                                    },
                                                    ...(Object.values(departments).length > 0 ? [{
                                                        id: 'department', by: (user.department && user.department.name !== 'NO DEPARTMENT') ? user.department.name : '-', label: 'Department', selected: (user.department && user.department.name !== 'NO DEPARTMENT') && user.department.name, type: 'dropdown', color: 'gray-5', options: Object.values(departments),
                                                        onChange: value => handleChangeUsers(user, 'department', value)
                                                    }] : []),
                                                    ...(editable ? [
                                                        {
                                                            id: 'permission', by: user.permission, label: 'Permission', type: 'dropdown', options: [{ name: 'Survey user' }, { name: 'Admin' }], selected: user.permission,
                                                            onChange: value => handleChangeUsers(user, 'permission', value.name)
                                                        },
                                                        {
                                                            id: 'actions', align: 'right', label: (
                                                                <div className="UsersList__actions">
                                                                    {user.permission === 'Admin' && <ButtonAction type="send" />}
                                                                    <ButtonAction type="delete--small" onClick={() => setDeleteUser(user.id)} />
                                                                </div>
                                                            )
                                                        }
                                                    ] : [])
                                                ] : [
                                                    {
                                                        id: 'edit', colspan: editable ? 7 : 5, label: (
                                                            <div className="UsersList__flex">
                                                                <InputField backgroundColor="white" placeholder="First name" name={'firstname'} value={user.firstnameTemp} onChange={value => handleChangeUsers(user, 'firstnameTemp', value)} />
                                                                <InputField backgroundColor="white" placeholder="Last name" name={'lastname'} value={user.lastnameTemp} onChange={value => handleChangeUsers(user, 'lastnameTemp', value)} />
                                                                <InputField backgroundColor="white" placeholder="Email" name={'email'} value={user.emailTemp} onChange={value => handleChangeUsers(user, 'emailTemp', value)} />
                                                                <div className="UsersList__actions">
                                                                    <ButtonAction type="save--small" onClick={() => handleChangeUsers(user, 'updateUser', true)} />
                                                                    <ButtonAction type="cancel--small" onClick={() => {
                                                                        handleChangeUsers(user, 'editMode', false);
                                                                    }} />
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                ]
                                            }
                                        )
                                    })}
                                    color="white"
                                />
                            </div>
                            {!!deleteUser && (
                                <Popup
                                    color="white"
                                    title={`Are you sure you want to delete ${users[deleteUser].firstname} ${users[deleteUser].lastname}?`}
                                    fields={[{ name: 'user', value: "This action can't be undone." }]}
                                    onSubmit={() => {
                                        setDeleteUser(null);
                                        propDeleteUser(deleteUser);
                                    }}
                                    submitText="Delete"
                                    onCancel={() => setDeleteUser(null)}
                                />
                            )}
                        </GridColumn>
                    </Grid>
                </GridColumn>
            </Grid>
            {open && (
                <AddUsersPopup
                    alias={propAlias}
                    markets={propMarkets}
                    departments={propDepartments}
                    onClose={() => setOpen(false)}
                    onSave={contacts => addContacts(contacts)}
                />
            )}
        </>
    )
}

export default UsersList;