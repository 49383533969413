import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import CheckboxField from '../CheckboxField/CheckboxField';
import Dropdown from '../Dropdown/Dropdown';
import InputField from '../InputField/InputField';
import './Table.scss';
import { sortItems } from '../../helpers';

/**
 * 
 * @param headers [{key, name}]
 * @param data [{id, columns: [{id, type (boolean, link, number, tags, inpuy, dropdown, checkbox), label, selected, onChange}]}]
 */
const Table = ({ headers, data, noSorting, color, fontSize, height }) => {
    const [rows, setRows] = useState([]);
    const [sort, setSort] = useState({ index: null, by: 'desc' });
    const [active, setActive] = useState([null, null]);

    useEffect(() => {
        if (data) {
            setRows(data);
        }
    }, [headers, data]);

    const getColumn = (data, row, column, top) => {
        const style = { ...(data.align ? { textAlign: data.align } : {}), ...(data.fontWeight ? { fontWeight: data.fontWeight } : {}), ...(data.fontSize ? { fontSize: data.fontSize } : {}), ...(data.width ? { width: data.width } : {}) };
        switch (data.type) {
            case 'boolean':
                return <td key={data.id} colSpan={data.colspan} className='Table__boolean' style={style}>{data.label ? <span className='Table__boolean--checkmark'>&#10003;</span> : <span className='Table__boolean--cross'>&#x2717;</span>}</td>
            case 'link':
                return <td key={data.id} colSpan={data.colspan} className='Table__link' style={style}><Link to={{ pathname: data.url, search: data.search }} state={data.extra} target={data.target}>{data.label}</Link></td>
            case 'number':
                return <td key={data.id} colSpan={data.colspan} className='Table__number' style={{ ...(data.align ? { textAlign: data.align } : {}) }}><span className={`${data.color ? `Table__column--${data.color}` : ''}`}>{data.label}{data.prefix ? data.prefix : ''}</span></td>
            case 'tags':
                return <td key={data.id} colSpan={data.colspan} className='Table__tags' style={{ ...(data.align ? { textAlign: data.align } : {}) }}>{data.label.map(tag => <span key={tag.id}>{tag.name}</span>)}</td>
            case 'input':
                return <td key={data.id} colSpan={data.colspan} className='Table__input'><InputField backgroundColor={color} placeholder={data.label} name={data.label} value={data.value} onChange={value => data.onChange(value)} /></td>
            case 'dropdown':
            case 'dropdownWithAddition':
                return (
                    <td key={data.id} colSpan={data.colspan} className={`Table__dropdown${active[0] === row && active[1] === column ? '--active' : ''}`} onClick={() => setActive([row, column])}>
                        <Dropdown align={top ? 'top' : 'bottom'} color={data.color || color} options={data.options} selected={data.selected || false} label={data.label} onChange={data.onChange} variation={data.type} onClick={() => { }} error={!data.selected} />
                    </td>
                )
            case 'checkbox':
                return <td key={data.id} colSpan={data.colspan} style={style} className='Table__checkbox'><CheckboxField onChange={data.onChange} checked={data.value || false} />{data.label || ''}</td>
            default:
                return <td key={data.id} colSpan={data.colspan} style={style}>{data.label}</td>
        }
    }

    const sortTable = index => {
        const newRows = [...rows];
        const sortby = index !== sort.index || (index === sort.index && sort.by === 'asc') ? 'desc' : 'asc';
        setRows(newRows.sort((rowA, rowB) => {
            let labelA = rowA.columns[index].by || rowA.columns[index].label || false;
            let labelB = rowB.columns[index].by || rowB.columns[index].label || false;;
            const type = rowA.columns[index].type;

            if (rowA.columns[index].static) return -1;
            if (rowB.columns[index].static) return 1;

            return sortItems(type, sortby, labelA, labelB);
        }));
        setSort({ index, by: sortby });
    };

    return (
        <div className={`Table${color ? `--${color}` : ''} ${fontSize ? `Table--${fontSize}` : ''}`} style={{ height }}>
            <table>
                <thead>
                    <tr>
                        {headers.map((header, index) => (
                            <th
                                key={header.key || header.name}
                                className={`Table__header${noSorting ? '--nosorting' : ''}`}
                                style={{ width: header.width }}
                                onClick={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    return (!noSorting && e.target.tagName !== 'INPUT') && sortTable(index);
                                }}
                            >
                                <div className='Table__headerInner'><span>{header.name}</span>{!(noSorting || header.name === '') && <span className={`Table__sortby${index === sort.index ? `--${sort.by}` : ''}`}></span>}</div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {rows.map((row, rowIndex) => (
                        <tr key={row.id}>
                            {row.columns.map((data, columnIndex) => getColumn(data, rowIndex, columnIndex, rowIndex > 2 && rows.length - 2 <= rowIndex))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default Table;