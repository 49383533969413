import axios from "axios"
const url = process.env.REACT_APP_TCF_API;

export const getTypeform = async (survey, token, on, markets, departments) => axios.get(`${url}/surveys/${survey}/score?on=${on}&markets=${markets.map(m => `${m.id}:${m.name.replace(/&/g, '%26')}`).join(',')}&departments=${departments.map(d => `${d.id}:${d.name.replace(/&/g, '%26')}`).join(',')}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ ...res.data, status: 200 }))
    .catch(error => error.response);

export const getDistribution = async (survey, token) => axios.get(`${url}/surveys/${survey}/distribution`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ ...res.data, status: 200 }))
    .catch(error => error.response);
export const getSurveyRoles = async (survey, token, markets, departments) => axios.get(`${url}/surveys/${survey}/roles?markets=${markets.map(m => `${m.id}:${m.name.replace(/&/g, '%26')}`).join(',')}&departments=${departments.map(d => `${d.id}:${d.name.replace(/&/g, '%26')}`).join(',')}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res.data)
    .catch(error => error.response);;

export const getCompanies = async token => axios.get(url + '/companies', {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res.data)
    .catch(error => error.response);

export const getEnrichCompanies = async token => axios.get(url + '/companies/enrich', {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res.data)
    .catch(error => error.response);

export const getTypeformResponseById = async (token, typeformId, typeformToken) => axios.get(`${url}/typeform/${typeformId}/id/${typeformToken}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res.data)
    .catch(error => error.response)

export const getTypeformById = async (token, typeformId) => axios.get(`${url}/typeform/v2${typeformId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res.data)
    .catch(error => error.response);

export const getAllCompanies = async token => axios.get(`${url}/typeform/all`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res.data)
    .catch(error => error.response);

export const getAllForms = async token => axios.get(`${url}/typeform/allforms`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const createForm = async (token, id) => axios({
    method: 'post',
    url: `${url}/typeform/create/${id}`,
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompany = async (token, company) => axios.get(`${url}/companies/${company}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const addSurvey = async (token, survey) => axios({
    method: 'post',
    url: `${url}/surveys`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: survey
})
    .then(res => res)
    .catch(error => error.response);

export const getMailchimpMembers = async (token, id) => axios.get(`${url}/mailchimp/members/form/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getMembersAuth0 = async (token, typeformId) => axios({
    method: 'post',
    url: `${url}/users/filter`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        filter: `user_metadata.typeformId:"${typeformId}"`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanyMembers = async (token, company) => axios.get(`${url}/surveys/company/${company}/count`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getForm = async (token, id) => axios.get(`${url}/typeform/form/${id}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanySurveys = async (token, company) => axios.get(`${url}/surveys/company/${company}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const addUser = async (token, user) => axios({
    method: 'post',
    url: `${url}/users`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: user
})
    .then(res => res)
    .catch(error => error.response);

export const addCompany = async (token, name) => axios({
    method: 'post',
    url: `${url}/companies`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: { name }
})
    .then(res => res)
    .catch(error => error.response);

export const deleteUser = (token, id) => axios({
    method: 'delete',
    url: `${url}/users/${id}`,
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const deleteSurvey = (token, id) => axios({
    method: 'delete',
    url: `${url}/surveys/${id}`,
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getSurvey = (token, id, withRecipients = false) => axios.get(`${url}/surveys/${id}?with_recipients=${withRecipients}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const updateSurvey = (token, survey) => axios({
    method: 'put',
    url: `${url}/surveys/${survey.id}/params`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: survey
})
    .then(res => res)
    .catch(error => error.response);

export const updateUser = (token, user) => axios({
    method: 'put',
    url: `${url}/users/contactinfo/${user.id}`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: user
})
    .then(res => res)
    .catch(error => error.response);

export const updateRoleUser = (token, user) => axios({
    method: 'put',
    url: `${url}/users/role/${user.id}`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        role: user.role
    }
})
    .then(res => res)
    .catch(error => error.response);

export const updatePermissionUser = (token, user) => axios({
    method: 'put',
    url: `${url}/users/permission/${user.id}`,
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        permission: user.permission
    }
})
    .then(res => res)
    .catch(error => error.response);

export const sendSurvey = (token, id) => axios({
    method: 'post',
    url: `${url}/surveys/${id}/send`,
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const deleteCompany = (token, id) => axios({
    method: 'delete',
    url: `${url}/companies/${id}`,
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getUserSurveys = (token, id) => axios.get(`${url}/users/${id}/surveys`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanyGoals = (token, id) => axios.get(`${url}/companies/${id}/goals`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const addCompanyGoal = (token, id, goal) => axios({
    url: `${url}/companies/${id}/goals`,
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: goal
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanyUsers = (token, companyId) => axios.get(`${url}/users?company=${companyId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanyMarkets = (token, companyId) => axios.get(`${url}/markets?company=${companyId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanyDepartments = (token, companyId) => axios.get(`${url}/departments?company=${companyId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => res)
    .catch(error => error.response);

export const addUsers = (token, companyId, users) => axios({
    url: `${url}/users/batch`,
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        companyId,
        users
    }
})
    .then(res => res)
    .catch(error => error.response);

export const addMarket = (token, companyId, name) => axios({
    url: `${url}/markets`,
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        companyId,
        name
    }
})
    .then(res => res)
    .catch(error => error.response);

export const updateMarket = (token, id, companyId, user) => axios({
    url: `${url}/markets/${id}`,
    method: 'put',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        companyId,
        user
    }
})
    .then(res => res)
    .catch(error => error.response);

export const addDepartment = (token, companyId, marketId, name) => axios({
    url: `${url}/departments`,
    method: 'post',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        companyId,
        marketId,
        name
    }
})
    .then(res => res)
    .catch(error => error.response);

export const updateDepartment = (token, companyId, marketId, name, user) => axios({
    url: `${url}/departments`,
    method: 'put',
    headers: {
        Authorization: `Bearer ${token}`
    },
    data: {
        companyId,
        marketId,
        name,
        user
    }
})
    .then(res => res)
    .catch(error => error.response);

export const getCompanyById = (token, companyId) => axios.get(`${url}/companies/${companyId}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ ...res.data, status: 200 }))
    .catch(error => error.response);

export const getCarbonPrice = (token, survey, on, markets, departments) => axios.get(`${url}/surveys/${survey}/carbon?on=${on}&markets=${markets.map(m => `${m.id}:${m.name.replace(/&/g, '%26')}`).join(',')}&departments=${departments.map(d => `${d.id}:${d.name.replace(/&/g, '%26')}`).join(',')}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ ...res.data, status: 200 }))
    .catch(error => error.response);

export const getTransitionImpactHorizon = (token, survey, on, markets, departments) => axios.get(`${url}/surveys/${survey}/impact/horizon?on=${on}&markets=${markets.map(m => `${m.id}:${m.name.replace(/&/g, '%26')}`).join(',')}&departments=${departments.map(d => `${d.id}:${d.name.replace(/&/g, '%26')}`).join(',')}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ data: res.data, status: 200 }))
    .catch(error => error.response);

export const getTimeScaleNetZero = (token, survey, on, markets, departments) => axios.get(`${url}/surveys/${survey}/timescalenetzero?on=${on}&markets=${markets.map(m => `${m.id}:${m.name.replace(/&/g, '%26')}`).join(',')}&departments=${departments.map(d => `${d.id}:${d.name.replace(/&/g, '%26')}`).join(',')}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ data: res.data, status: 200 }))
    .catch(error => error.response);

export const getOffsettingProgram = (token, survey, on, markets, departments) => axios.get(`${url}/surveys/${survey}/offsetting?on=${on}&markets=${markets.map(m => `${m.id}:${m.name.replace(/&/g, '%26')}`).join(',')}&departments=${departments.map(d => `${d.id}:${d.name.replace(/&/g, '%26')}`).join(',')}`, {
    headers: {
        Authorization: `Bearer ${token}`
    }
})
    .then(res => ({ data: res.data, status: 200 }))
    .catch(error => error.response);