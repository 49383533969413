import { cloneElement, useEffect, useState } from "react";
import { getTransitionImpact, getTransitionImpactHorizon } from "../api";

const TransitionImpactContainer = ({ user, survey, children, markets, departments }) => {
    const [error, setError] = useState(false);
    const [on, setOn] = useState('average');
    const [data, setData] = useState({
        average: {},
        roles: {},
        markets: {},
        departments: {},
        horizon: []
    });
    const [ready, setReady] = useState(false);

    useEffect(() => {
        if (user && user.__raw) {
            setReady(true);
            getData(on);
        } else {
            setReady(false);
        }
    }, [user, markets, departments]);

    const getData = async on => {
        if (ready) {
            let newData = { ...data };
            let temp, horizon;
            if (on !== 'average') {
                horizon = await getTransitionImpactHorizon(user.__raw, survey, on, Object.values(markets), Object.values(departments));
                newData = { ...newData, [on]: { horizon: horizon.data } };
                setOn(on);
            } else {
                horizon = await getTransitionImpactHorizon(user.__raw, survey, 'average', Object.values(markets), Object.values(departments));
                newData = { ...newData, 'average': { horizon: horizon.data } };
                setOn('average');
            }
            setData(newData);
        }
    }

    return cloneElement(children, { error, data, getData, ready });
};

export default TransitionImpactContainer;