import React, { useEffect, useState } from "react";
import Papa from 'papaparse';
import Table from "../../Table/Table";
import CheckboxField from "../../CheckboxField/CheckboxField";
import Popup from "../../Popup/Popup";
import { generateToken } from "../../../helpers";
import AddUsersPopupManual from "./AddUsersPopupManual";
import Dropdown from "../../Dropdown/Dropdown";
import ButtonPlainText from "../../Button/ButtonPlainText";
import ButtonAction from "../../Button/ButtonAction";
import "./AddUsersPopup.scss";
import { Flex } from "@chakra-ui/react";

const AddUsersPopup = ({ onSave, onClose, alias, markets: propMarkets, departments: propDepartments }) => {
    const [users, setUsers] = useState([]);
    const [markets, setMarkets] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [allSelected, setAllSelected] = useState(false);
    const [openAddFile, setOpenAddFile] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedMarket, setSelectedMarket] = useState(null);
    const [selectedDepartment, setSelectedDepartment] = useState(null);

    useEffect(() => {
        setMarkets(propMarkets);
        setDepartments(propDepartments);
    }, [propMarkets, propDepartments]);

    const selectUser = user => {
        const newUsers = { ...users, [user.id]: { ...user, selected: !user.selected } };
        setUsers(newUsers);
        const userArray = Object.values(newUsers);
        for (let i = 0; i < userArray.length; i++) {
            const user = userArray[i];
            if (!user.selected) {
                setAllSelected(false);
                return;
            }
        }
        setAllSelected(true);
    }

    const selectOrUnselectAll = () => {
        setUsers(Object.values(users).reduce((prevUsers, currUser) => ({ ...prevUsers, [currUser.id]: { ...currUser, selected: !allSelected } }), {}));
        setAllSelected(!allSelected);
    }

    const readCSV = file => {
        const roles = alias && Object.values(alias).map(a => ({ ...a, name: a.alias || a.name, default: a.name }));
        Papa.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: results => {
                setUsers(results.data.reduce((prevUsers, currUser) => {
                    const token = generateToken();
                    const csvRole = currUser['Role'];
                    const csvMarket = currUser['Market'];
                    const csvDepartment = currUser['Department'];
                    const role = roles.find(r => r.name.toLowerCase() === csvRole.toLowerCase()) || selectedRole;
                    const market = markets.find(m => m.name.toLowerCase() === csvMarket.toLowerCase()) || selectedMarket;
                    const department = departments.find(d => d.name.toLowerCase() === csvDepartment.toLowerCase()) || selectedDepartment;
                    
                    return {
                        ...prevUsers,
                        [token]: {
                            id: token,
                            selected: true,
                            firstname: currUser['First Name'] || currUser['Given Name'],
                            lastname: currUser['Last Name'] || currUser['Family Name'],
                            email: currUser['E-mail Address'] || currUser['E-mail 1 - Value'],
                            role,
                            market,
                            department,
                            permission: 'Survey user'
                        }
                    };
                }, {}));
                setAllSelected(true);
            }
        })
    }

    const handleOnSave = () => {
        onSave(Object.values(users).filter(user => user.selected).reduce((prevUsers, currUser) => ({ ...prevUsers, [currUser.id]: currUser }), {}));
        onClose();
    }

    return (
        <>
            <Popup
                color="white"
                title={(
                    <div className="AddUsersPopup__header">
                        <span>Add recipients</span>
                        <div style={{ textAlign: 'right' }}>
                            <ButtonAction type="primary" text="Upload file" onClick={() => setOpenAddFile(true)} />
                        </div>
                    </div>
                )}
                fields={[
                    {
                        name: 'manual', type: 'component', value: (
                            <AddUsersPopupManual
                                alias={alias}
                                markets={markets}
                                departments={departments}
                                onAdd={user => selectUser(user)}
                            />
                        )
                    },
                    {
                        name: 'users', type: 'component', overflow: true, value: (
                            <div>
                                <Table
                                    headers={[
                                        {
                                            key: 'select', name: <CheckboxField onChange={selectOrUnselectAll}
                                                checked={allSelected}
                                            />, width: '0'
                                        },
                                        { key: 'firstname', name: 'First Name' },
                                        { key: 'lastname', name: 'Last Name' },
                                        { key: 'email', name: 'Email' },
                                        { key: 'role', name: 'Role' },
                                        { key: 'market', name: 'Market' },
                                        ...(departments.length > 0 ? [{ key: 'department', name: 'Department' }] : []),
                                    ]}
                                    data={Object.values(users).map(user => ({
                                        id: user.id,
                                        columns: [
                                            { id: 'select', value: user.selected, type: 'checkbox', onChange: () => selectUser(user) },
                                            { id: 'firstname', label: 'First Name', type: 'input', value: user.firstname, onChange: value => setUsers({ ...users, [user.id]: { ...user, firstname: value } }) },
                                            { id: 'lastname', label: 'Last Name', type: 'input', value: user.lastname, onChange: value => setUsers({ ...users, [user.id]: { ...user, lastname: value } }) },
                                            { id: 'email', label: 'Email', type: 'input', value: user.email, onChange: value => setUsers({ ...users, [user.id]: { ...user, email: value } }) },
                                            { id: 'role', label: 'Role', color: 'gray-5', selected: user.role && user.role.name, type: 'dropdown', options: alias && Object.values(alias).map(a => ({ ...a, name: a.alias || a.name, default: a.name })), onChange: value => setUsers({ ...users, [user.id]: { ...user, role: value } }) },
                                            {
                                                id: 'market',
                                                by: !user.market ? user.market : '--',
                                                label: 'Market',
                                                selected: user.market && user.market.name,
                                                type: 'dropdown',
                                                color: 'gray-5',
                                                options: Object.values(markets),
                                                onChange: value => {
                                                    setUsers({ ...users, [user.id]: { ...user, market: value } });
                                                }
                                            },
                                            ...(departments.length > 0 ? [{
                                                id: 'department',
                                                by: !user.department ? user.department : '--',
                                                label: 'Department',
                                                selected: user.department && user.department.name,
                                                type: 'dropdown',
                                                color: 'gray-5',
                                                options: departments,
                                                onChange: (value, i) => {
                                                    setUsers({ ...users, [user.id]: { ...user, department: value } });
                                                }
                                            }] : [])
                                        ]
                                    }))}
                                    color="white"
                                    fontSize="fs-s"
                                    height="25rem"
                                />
                            </div>
                        )
                    }
                ]}
                onSubmit={() => handleOnSave(users)}
                submitDisabled={Object.values(users).filter(user => user.selected).length === 0}
                submitText={`Import (${Object.values(users).filter(user => user.selected).length})`}
                onCancel={onClose}
            />
            {openAddFile && <Popup
                color="white"
                title="Apply the following organisational belonging to the contacts you import"
                fields={[
                    {
                        name: 'manual', type: 'component', value: (
                            <div>
                                <p>This is only neccessary if the information isn't already in the file.
                                    You can also add these manually to each contact later.</p>
                                <Flex className="AddUsersPopup__units" h="50px" my={5}>
                                    <Dropdown
                                        color="gray-5"
                                        options={alias && Object.values(alias).map(a => ({ ...a, name: a.alias || a.name, default: a.name }))}
                                        label="Role"
                                        selected={selectedRole && selectedRole.name}
                                        onChange={value => setSelectedRole(value)}
                                    />
                                    <Dropdown
                                        color="gray-5"
                                        options={markets}
                                        label="Market"
                                        selected={selectedMarket && selectedMarket.name}
                                        onChange={value => setSelectedMarket(value)}
                                    />
                                    {departments.length > 0 && <Dropdown
                                        color="gray-5"
                                        options={departments}
                                        label="Department"
                                        selected={selectedDepartment && selectedDepartment.name}
                                        onChange={value => setSelectedDepartment(value)}
                                    />}
                                </Flex>
                            </div>
                        )
                    },
                    {
                        name: 'upload', type: 'component', value: (
                            <div>
                                <label className="AddUsersPopup__file--button" for="upload-button">{file && file.name !== '' ? file.name : <ButtonPlainText text="Choose file" />}</label>
                                <input className="AddUsersPopup__file" id="upload-button" type="file" accept={".csv"} onChange={e => {
                                    setFile(e.target.files[0])
                                }} />
                            </div>
                        )
                    }
                ]}
                onSubmit={() => {
                    readCSV(file);
                    setOpenAddFile(false);
                    setFile(null);
                }}
                submitDisabled={!(file && file.name !== '')}
                submitText="Upload"
                onCancel={() => setOpenAddFile(false)}
            />}
        </>
    )
}

export default AddUsersPopup;