import React from "react";
import Grid from "../gridSystem/Grid/Grid";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import Curve from "../visualization/Curve/Curve";
import { Box, Flex } from "@chakra-ui/react";
import './Hero.scss';

const Hero = ({ score, details, name, hideScore }) => (
    <div className="Hero">
        <Grid>
            <GridColumn size={6} desktopOrder={0}>
                <div className="Hero__content">
                    <div className="Hero__contentWrapper">
                        <h1 className={`Hero__title${!name ? '--hidden' : ''}`}>{name || 'Company'}</h1>
                    </div>
                </div>
            </GridColumn>
            {!hideScore && (
                <GridColumn size={6} desktopOrder={1} gridRow="span 2" height="100%">
                    <Flex h="100%" alignItems="end">
                        <Box w="100%">
                            <Curve score={Math.round(score)} />
                        </Box>
                    </Flex>
                </GridColumn>
            )}
            <GridColumn size={6} desktopOrder={2}>
                <div className="Hero__details">
                    {details}
                </div>
            </GridColumn>
            <GridColumn size={12} desktopOrder={3}>
                <div className="Hero__line"></div>
            </GridColumn>
        </Grid>
    </div >
)

export default Hero;