import React from "react";
import "./Ylines.scss";

const Ylines = ({ max, min, step, positiveNegative, yLabelTop, yLabelBottom }) => {
    let lines = [];
    for (let i = max; i >= min; i -= step) {
        lines.push(
            <div
                key={i}
                className={`Ylines__line${(min < 0 && Math.round((positiveNegative ? Math.abs(i) : i) * 10) / 10 === 0) ? '--0' : ''}`}
            >
                <span>{Math.round((positiveNegative ? Math.abs(i) : i) * 10) / 10}</span>
            </div>
        );
    }

    return (
        <div className="Ylines">
            <div className="Ylines__yLabels">
                <div className="Ylines__yLabel">
                    <span>{yLabelTop}</span>
                </div>
                <div className="Ylines__yLabel">
                    <span>{yLabelBottom}</span>
                </div>
            </div>
            {lines}
        </div>
    )
}

export default Ylines;