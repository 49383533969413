import React, { cloneElement } from "react";
import Footer from "../Footer/Footer";
import { Grid, GridItem } from "@chakra-ui/react";
import SidebarLeft from "../overlays/SidebarLeft/SidebarLeft";
import './Page.scss';

const Page = ({ children, data, loading, companies, user, name, backgroundColor, active }) => (
    <>
        <Grid className={`Page${backgroundColor ? `--${backgroundColor}` : ''}`} templateColumns='3.5rem 1fr'>
            <GridItem>
                <SidebarLeft user={user} active={active} />
            </GridItem>
            <GridItem minH="100vh">
                {cloneElement(children, { data, loading, name })}
            </GridItem>
            <GridItem colSpan={2}>
                <Footer />
            </GridItem>
        </Grid>
    </>
);

export default Page;