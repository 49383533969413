import React from "react";
import BarChart from "../../BarChart/BarChart";
import constants from "../../../constants";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import Grid from "../../gridSystem/Grid/Grid";
import Block from "../../Block/Block";
import Speedometer from "../../Speedometer/Speedometer";
import Description from "../../overlays/Description/Description";

const OverviewL0 = ({ score, data, goal, loading }) => (
    <div className='Overview__barchart'>
        <Grid>
            {(!loading && data && data.children) && Object.values(data.children).map(d => (
                <GridColumn key={d.id} size={4} noPadding={true}>
                    <Block color="white" fill={true} gridStyle={{ gridTemplateRows: '160px' }}>
                        <>
                            <Description description={data ? data.children[d.id].description : ''} />
                            <div className='Overview__center'>
                                <Speedometer
                                    score={Math.round(!loading && data && data.children ? data.children[d.id].score : score)}
                                    color={constants.l0[d.id].colorCode}
                                    size={10}
                                    pinBorder={false}
                                    fontSize="fs-xxxl"
                                    arcWidth={0.2}
                                    goal={goal && Object.keys(constants.l1[d.id]).reduce((prev, curr) => prev + goal[curr], 0) / 2}
                                />
                            </div>
                            <div>
                                <h2 className='Overview__title Overview--desktop'>{constants.l0[d.id].name}</h2>
                                <h2 className={`Overview__title Overview__title--${constants.l0[d.id].color} Overview__title--mobile`}>{constants.l0[d.id].name}</h2>
                            </div>
                            <BarChart
                                numberOnTop={true}
                                currentGoal={null}
                                data={Object.values(d.children).map(child => ({
                                    id: child.id,
                                    label: child.description,
                                    score: child.score,
                                    data: [{
                                        id: child.id,
                                        label: child.description,
                                        score: child.score,
                                        color: constants.l0[d.id].color,
                                        colorCode: constants.l0[d.id].colorCode,
                                    }],
                                    ...(goal ? { goal: goal[child.label] } : {})
                                }))
                                }
                            />
                        </>
                    </Block>
                </GridColumn>
            ))}
        </Grid>
    </div>
);

export default OverviewL0;