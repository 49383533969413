import React from "react";
import './Alert.scss';

const Alert = ({ text }) => (
    <div className="Alert">
        {text ?
            <p>{text}</p> :
            (
                <p>We're sorry, something went wrong!</p>
            )}
    </div>
)

export default Alert;