import { cloneElement, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { addCompanyGoal, getCompanyGoals } from "../api";
import constants from "../constants";

const GoalContainer = ({ children }) => {
    const { company } = useParams();
    const { user, getIdTokenClaims } = useAuth0();
    const [currentUser, setCurrentUser] = useState(user);
    const [goals, setGoals] = useState([]);
    const [currentGoal, setCurrentGoal] = useState(Object.values(constants.l1).reduce((prevl0, currl0) => ({ ...prevl0, ...(Object.keys(currl0).reduce((prev, curr) => ({ ...prev, [curr]: 0 }), {})) }), {}));

    useEffect(() => {
        getIdTokenClaims().then(res => {
            setCurrentUser(res);
            getCompanyGoals(res.__raw, company).then(res => {
                if (res.status === 200) {
                    setGoals(res.data);
                    if (res.data.length > 0) {
                        setCurrentGoal(res.data[0]);
                    }
                }
            });
        });
    }, [getIdTokenClaims, company]);

    const addGoal = goal => {
        setCurrentGoal(goal);
        addCompanyGoal(currentUser.__raw, company, goal);
    };

    return cloneElement(children, { currentGoal, goals, addGoal });
}

export default GoalContainer;