import React from "react";
import './CheckboxField.scss';

const CheckboxField = ({ id, onChange, checked }) => (
    <div className="CheckboxField">
        <input className="CheckboxField__checkbox" id={id} name={id} type="checkbox" onChange={onChange} checked={checked} />
        <label className="CheckboxField__label" for={id}></label>
    </div>
);

export default CheckboxField;