import React, { useEffect, useState } from "react";
import { Flex, Heading, Skeleton } from "@chakra-ui/react";
import Dropdown from '../../Dropdown/Dropdown';

const OverviewHeader = ({ selectedSurvey, surveys: propsSurveys, markets: propsMarkets, departments: propsDepartments, onUpdateSelection }) => {
    const [survey, setSurvey] = useState(null);
    const [markets, setMarkets] = useState([]);
    const [departments, setDepartments] = useState([]);

    useEffect(() => {
        setMarkets((propsMarkets && Object.values(propsMarkets)) || []);
        setDepartments((propsDepartments && Object.values(propsDepartments)) || []);
    }, [propsMarkets, propsDepartments]);

    useEffect(() => {
        if (selectedSurvey) {
            setSurvey(selectedSurvey);
        } else if (propsSurveys && propsSurveys.length > 0) {
            setSurvey(propsSurveys[0]);
        }
    }, [propsSurveys]);

    const handleOnChange = (key, value) => {
        let newSurvey = survey;
        let newMarkets = markets;
        let newDepartments = departments;
        if (key === 'market') {
            const index = newMarkets.findIndex(market => market.name === value.name);
            const market = newMarkets[index];
            newMarkets = [...newMarkets.slice(0, index), { ...market, selected: !market.selected }, ...newMarkets.slice(index + 1)];
            setMarkets(newMarkets);
            onUpdateSelection('markets', newMarkets.filter(market => market.selected));
        } else if (key === 'department') {
            const index = newDepartments.findIndex(department => department.name === value.name);
            const department = newDepartments[index];
            newDepartments = [...newDepartments.slice(0, index), { ...department, selected: !department.selected }, ...newDepartments.slice(index + 1)];
            setDepartments(newDepartments);
            onUpdateSelection('departments', newDepartments.filter(department => department.selected));
        } else if (key === 'survey') {
            newSurvey = value;
            setSurvey(newSurvey);
            if (!survey || value.id !== survey.id) {
                onUpdateSelection('survey', value.id);
            }
        };
    };

    return (
        <Flex height={70} py={13} px={12} gap={10} maxW={1400}>
            <Heading size="lg">Dashboard</Heading>
            <Flex gap={4} w="100%">
                <Dropdown
                    label="Survey"
                    color="white"
                    options={(propsSurveys && propsSurveys) || []}
                    selected={survey?.name || ''}
                    fontSize='$fs-s'
                    onChange={value => handleOnChange('survey', value)}
                />
                <Dropdown
                    label="Markets"
                    color="white"
                    options={markets ? Object.values(markets) : []}
                    multipleSelection={true}
                    selected={markets.filter(market => market.selected)}
                    fontSize='$fs-s'
                    onChange={value => handleOnChange('market', value)}
                />
                <Dropdown
                    label="Departments"
                    color="white"
                    options={departments ? Object.values(departments) : []}
                    multipleSelection={true}
                    selected={departments.filter(department => department.selected)}
                    fontSize='$fs-s'
                    onChange={value => handleOnChange('department', value)}
                />
            </Flex>
        </Flex>
    )
};

export default OverviewHeader;