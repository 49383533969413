import React, { useEffect, useRef, useState } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import colors from "../../styles/colors";
import BarListYlines from "../BarList/BarListYlines";
import './Slider.scss';

const Slider = ({ startValue, currentValue, onChange, color, sliderColor, backgroundColor, disabled, reset }) => {
    const line = useRef();
    const [x, setX] = useState(0);
    const [pos, setPos] = useState(0);
    const [isDragging, setIsDragging] = useState(false);
    const [start, setStart] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        const w = line.current.offsetWidth;
        const p = Math.round(startValue / 100 * w);
        setX(p);
        setPos(p);
        setWidth(line.current.offsetWidth);
    }, [startValue, reset]);

    const handleDraggable = e => {
        e.preventDefault();
        e.stopPropagation();

        if (e.clientX === 0) return x;
        let p = pos + e.clientX - start;
        if (p < 0) p = 0;
        if (p > width) p = width;
        const offset = Math.round(p / width * 100) * width;
        setX(offset / 100);
        onChange(Math.round(p / width * 100));
        return offset;
    };

    return (
        <div className="Slider">
            <div ref={line} className="Slider__wrapper">
                <BarListYlines lines={[20, 40, 60, 80, 100]} hideScale={true} height="calc(100% + 0.5rem)" />
                <ProgressBar
                    progress={Math.round(currentValue)}
                    color={color}
                    withNumber={true}
                    height={'1.8rem'}
                    hasBackground={true}
                    backgroundColor={backgroundColor || 'gray'}
                    fontWeight={400}
                    fontColor={colors.color__gray1}
                />
                <span className={`Slider__line${sliderColor ? `--${sliderColor}` : ''}`} style={{ transform: `translate(${x - 6}px, 0)` }}></span>
                <span
                    className={`${isDragging ? 'Slider__line--dragging' : `Slider__line${sliderColor ? `--${sliderColor}` : ''}`}`}
                    style={{ transform: `translate(${x - 6}px, 0)` }}
                    onDragStart={e => {
                        if (disabled) {
                            e.preventDefault();
                        }
                        if (!disabled) {
                            setStart(e.clientX);
                            setIsDragging(true);
                        }
                    }}
                    onDragCapture={handleDraggable}
                    onDragEnd={e => {
                        if (!disabled) {
                            const newPos = handleDraggable(e);
                            setPos(newPos / 100);
                            setIsDragging(false);
                        }
                    }}
                    draggable={!disabled}
                >
                </span>
            </div>
        </div>
    )
}

export default Slider;