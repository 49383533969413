import React, { useEffect, useState } from "react";
import { createForm, getAllForms, getCompanies } from "../../../api";
import { Grid, GridItem } from "@chakra-ui/react";
import Block from "../../Block/Block";
import Button from "../../Button/Button";
import Popup from "../../Popup/Popup";
import Table from "../../Table/Table";
import CompaniesBlock from "../../CompaniesBlock/CompaniesBlock";
import './Admin.scss';

const Admin = ({ user }) => {
    const [forms, setForms] = useState([]);
    const [openCreateForm, setOpenCreateForm] = useState(false);
    const [createError, setCreateError] = useState(false);

    useEffect(() => {
        if (user && user.__raw) {
            getCompanies(user.__raw).then(res => {
                getAllForms(user.__raw).then(res => {
                    if(res.status !== 200) throw res;
                    setForms(res.data)
                })
                .catch(e => {
                    setForms([])
                });
            });
        }
    }, [user]);

    const handleOnSubmitNewForm = form => {
        const { value } = form;
        setCreateError(false);
        if (user && user.__raw && value !== '') {
            createForm(user.__raw, { title: value }).then(res => {
                if (res.status === 200) {
                    const { data } = res;
                    let date = new Date();
                    date = date.getFullYear() + '-' + (`0${date.getMonth() + 1}`).slice(-2) + '-' + (`0${date.getDate()}`).slice(-2)
                    setForms(form => [...form, { id: data.id, title: value, last_updated_at: date, created_at: date, is_public: true }]);
                    setOpenCreateForm(false);
                } else {
                    setCreateError(true);
                }
            })
        }
    }

    return (
        <Grid maxW={1400} p={8} rowGap={4}>
            <GridItem>
                <CompaniesBlock token={user && user.__raw} />
            </GridItem>
            <GridItem>
                <Block col='1' title="Forms in Typeform" color="white">
                    <>
                        <Table
                            headers={[{ name: 'ID' }, { name: 'Title' }, { name: 'Created' }, { name: 'Updated' }, { name: 'Public' }]}
                            data={forms?.length > 0 && forms.map(form => ({
                                id: form.id,
                                columns: [
                                    { id: 0, type: 'link', label: form.id, url: `/admin/typeform/${form.id}` },
                                    { id: 1, type: 'link', label: form.title, url: `/admin/typeform/${form.id}` },
                                    { id: 2, type: 'text', label: form.created_at && form.created_at.split("T")[0] },
                                    { id: 3, type: 'text', label: form.last_updated_at && form.last_updated_at.split("T")[0] },
                                    { id: 4, type: 'boolean', label: form.is_public },
                                ]
                            }))}
                        />
                        <div className="Admin__right">
                            <div className="Admin__button">
                                <Button onClick={() => setOpenCreateForm(true)}><div className="Admin__buttonInner">Create Typeform</div></Button>
                            </div>
                            {openCreateForm && <Popup
                                title="Set a title on your Typeform"
                                fields={[{ type: 'text', name: 'Title' }]}
                                submitText="Create"
                                onSubmit={form => handleOnSubmitNewForm(form[0])}
                                onCancel={() => setOpenCreateForm(false)}
                                error={createError && "Sorry, try again!"}
                            />}
                        </div>
                    </>
                </Block>
            </GridItem>
        </Grid>
    )
};

export default Admin;