import React, { cloneElement, useRef, useState } from "react";
import "./PopupSmall.scss";
import Steps from "../Steps/Steps";
import { fontWeights } from "../../styles/fonts";
import useOutsideClick from "../../hooks/useOutsideClick";
import ButtonAction from "../Button/ButtonAction";

const PopupSmall = ({ children }) => {
    const ref = useRef();
    const [open, setOpen] = useState(false);

    useOutsideClick(ref, () => {
        if (open) {
            setOpen(false);
        }
    });

    return (
        <div ref={ref} className="PopupSmall">
            <div className={`PopupSmall__box PopupSmall__box${open ? '--open' : '--closed'}`}>
                <div className="PopupSmall__close">
                    <ButtonAction type="close--small" onClick={() => setOpen(false)} />
                </div>
                <h4 className="PopupSmall__title">What happens when I press send?</h4>
                <Steps
                    type="number"
                    steps={[
                        { id: 1, title: "An automatic email is sent out to all your recipients, inviting them to take the survey." },
                        { id: 2, title: "Lean back and let the tool collect and aggregate the responses for you." },
                        { id: 3, title: "Any recipient who hasn’t responded to the survey after a week will get an automatic reminder." }
                    ]}
                    fontStyle={{ fontWeight: fontWeights.fwRegular }}
                />
            </div>
            {cloneElement(children, { onClick: () => setOpen(!open) })}
        </div>
    )
}

export default PopupSmall;