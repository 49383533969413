import React, { useRef, useState } from "react";
import InputField from "../InputField/InputField";
import useOutsideClick from "../../hooks/useOutsideClick";
import ButtonAction from "../Button/ButtonAction";
import CheckboxField from "../CheckboxField/CheckboxField";
import './Dropdown.scss';
import { Box, Text } from "@chakra-ui/react";

const Dropdown = ({ options, selected, label, onChange, onClick, color, variation, fontSize, multipleSelection, error, align, fullWidth }) => {
    const ref = useRef();
    const [open, setOpen] = useState(false);
    const [addNew, setAddNew] = useState(false);
    const [newOption, setNewOption] = useState('');

    const handleAddNew = e => {
        e.preventDefault();
        e.stopPropagation();
        onChange(newOption);
        setAddNew(false);
        setOpen(false);
        setNewOption('');
    }

    useOutsideClick(ref, () => {
        if (open) {
            setOpen(false);
        }
    });

    return (
        <div ref={ref} className={`Dropdown Dropdown${color ? `--${color}` : ''} ${fontSize ? `Dropdown--${fontSize}` : ''} ${fullWidth ? 'Dropdown--fullWidth' : ''}`}>
            <div className={`Dropdown__selected ${error ? 'Dropdown__selected--error' : ''}`} onClick={() => {
                setOpen(!open);
                onClick(!open);
            }}>
                <Box w="100%">
                    {(selected && selected.length > 0 && !multipleSelection) ?
                        (
                            <>
                                <Text fontSize="xs" color="primary" fontWeight="bold">{label}</Text>
                                <Text fontSize="sm" whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">{selected}</Text>
                            </>
                        ) :
                        <Text fontSize="sm" color="primary" fontWeight="bold">{selected?.length > 0 ? (
                            `${selected.length} ${label.toLowerCase()} selected`
                        ) : label}</Text>

                    }
                </Box>
            </div>
            <ul className={`Dropdown__select Dropdown__select--${open ? 'open' : 'close'} ${align ? `Dropdown__select--${align}` : ''}`}>
                {options.map(option => (
                    <li key={option.name} className='Dropdown__option' onClick={() => {
                        onChange(option);
                        !multipleSelection && setOpen(false);
                    }}><div className='Dropdown__optionWrapper'>{multipleSelection && <CheckboxField checked={option.selected} />}{option.name}</div></li>
                ))}
                {variation === 'dropdownWithAddition' && (
                    <li className='Dropdown__option--addition' onClick={() => setAddNew(true)}>{addNew ? <div style={{ display: 'flex', alignItems: 'center' }}><InputField backgroundColor="white" fontSize="fs-s" value={newOption} onChange={value => setNewOption(value)} /><div><ButtonAction type="add" onClick={e => handleAddNew(e)} /></div></div> : '+ Add new'}</li>
                )}
            </ul>
        </div>
    )
}

export default Dropdown;