import { extendTheme } from "@chakra-ui/theme-utils";

const theme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "gray.50",
                color: "black"
            },
            h1: { color: "black" },
            h2: { color: "black" },
            h3: { color: "black" },
        }
    },
    fonts: {
        body: "'IBM Plex Sans', sans-serif",
        heading: "'IBM Plex Sans', sans-serif",
        mono: "'IBM Plex Sans', sans-serif",
    },
    colors: {
        black: "#0f0f0f",
        white: "#ffffff",
        primary: "#31B468",
        gray: {
            50: "#F5F5F5",
            100: "#ededed",
            400: "#d0d0d0",
            500: "#989898",
            700: "#333333",
            900: "#222222"
        },
        overview: {
            yellow: "#FFD166",
            blue: "#118AB2",
            pink: "#EF476F"
        }
    },
    breakpoints: {
    }
});

export default theme;