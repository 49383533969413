import React, { useEffect, useState } from 'react';
import { Box, Grid, GridItem, Skeleton } from '@chakra-ui/react';
import GridColumn from '../../gridSystem/GridColumn/GridColumn';
import Hero from '../../Hero/Hero';
import Roles from '../../Roles/Roles';
import { sortedRoles } from '../../../helpers';
import Sidebar from '../../Sidebar/Sidebar';
import OverviewL0 from './OverviewL0';
import OverviewL1 from './OverviewL1';
import Menubar from '../../Menubar/Menubar';
import CarbonPrice from '../../models/CarbonPrice/CarbonPrice';
import TransitionImpact from '../../models/TransitionImpact/TransitionImpact';
import CarbonPriceContainer from '../../../container/CarbonPriceContainer';
import TransitionImpactContainer from '../../../container/TransitionImpactContainer';
import TimeScaleNetZeroContainer from '../../../container/TimeScaleNetZeroContainer';
import OffsettingContainer from '../../../container/OffsettingContainer';
import OverviewHeader from './OverviewHeader';
import OverviewTabs from './OverviewTabs';
import { useLocation } from 'react-router-dom';
import constants from '../../../constants';
import BarChartDataModel from '../../models/BarChartDataModel/BarChartDataModel';
import './Overview.scss';

const Overview = ({ data, user, roles, selectedSurvey, loading, goals, getDataOnFilter, getData, markets, departments, surveys, selectedMarkets, selectedDepartments }) => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');
    const [score, setScore] = useState(0);
    const [sidebarOpen, setSidebarOpen] = useState(null);
    const [goal, setGoal] = useState(null);
    const [showGoal, setShowGoal] = useState(false);
    const [alias, setAlias] = useState(null);
    const [alignment, setAlignment] = useState(null);

    useEffect(() => {
        let randomNumber;

        if (loading) {
            randomNumber = setInterval(() => setScore(score => score < 95 ? score + Math.floor(Math.random() * 5) + 1 : 100), 250);
        }
        if (data) {
            setScore(data.score);
            setAlias({
                executive: selectedSurvey.executiveAlias,
                senior: selectedSurvey.seniorAlias,
                operational: selectedSurvey.operationalAlias
            });
            setAlignment({
                markets: selectedMarkets,
                departments: selectedDepartments,
                roles: {
                    ...constants.roles,
                    executive: {
                        ...constants.roles.executive,
                        name: selectedSurvey.executiveAlias || constants.roles.executive.name
                    },
                    senior: {
                        ...constants.roles.senior,
                        name: selectedSurvey.seniorAlias || constants.roles.senior.name
                    },
                    operational: {
                        ...constants.roles.operational,
                        name: selectedSurvey.operationalAlias || constants.roles.operational.name
                    }
                }
            })
        }
        if (!loading && !data) {
            setScore(0);
        }
        return () => clearInterval(randomNumber);
    }, [data, loading]);

    useEffect(() => {
        if (goals.length > 0) {
            setGoal(goals[0]);
        }
    }, [goals]);

    const handleSidebar = action => {
        if (action === 'open') {
            setSidebarOpen(true);
        } else {
            setSidebarOpen(false);
        }
    }

    const handleOnSaveSidebar = goal => {
        setGoal(goal);
        setShowGoal(true);
        setSidebarOpen(false);
    }

    const SkeletonDashboard = () => (
        <>
            <Grid
                gap={4}
                p={4}
                gridTemplateColumns="1fr 1fr 1fr"
                gridTemplateRows="186px 566px 1039px"
            >
                <GridItem colSpan={3}>
                    <Skeleton w="100%" h="100%"></Skeleton>
                </GridItem>
                <GridItem colSpan={1}>
                    <Skeleton w="100%" h="100%"></Skeleton>
                </GridItem>
                <GridItem colSpan={1}>
                    <Skeleton w="100%" h="100%"></Skeleton>
                </GridItem>
                <GridItem colSpan={1}>
                    <Skeleton w="100%" h="100%"></Skeleton>
                </GridItem>
                <GridItem colSpan={3}>
                    <Skeleton w="100%" h="100%"></Skeleton>
                </GridItem>
            </Grid>
        </>
    );

    return (
        <>
            <OverviewHeader
                selectedSurvey={selectedSurvey}
                surveys={surveys}
                markets={markets}
                departments={departments}
                onUpdateSelection={(type, value) => getData(type, value)}
            />
            <OverviewTabs />
            {(data && !loading) ? (
                <>
                    {(!page || page === 'overview') && (
                        <>
                            <Hero
                                score={score}
                                details={<Roles alias={alias} data={{ total: (roles && roles.total) || '-', ...sortedRoles.reduce((prevRoles, currRole) => ({ ...prevRoles, [currRole.name]: roles ? ((roles[currRole.name] && roles[currRole.name].count) || 0) : '-' }), {}) }} />}
                            />
                            <div className='Overview'>
                                <Sidebar
                                    active={sidebarOpen}
                                    handleClose={() => handleSidebar('close')}
                                    goals={goals}
                                    values={data && Object.values(data.children).reduce((prevChildren, currChild) => ({ ...prevChildren, ...Object.values(currChild.children).reduce((prev, curr) => ({ ...prev, [curr.id]: curr.score }), {}) }), {})}
                                    handleOnSave={handleOnSaveSidebar}
                                />
                                <div className='Overview__wrapper'>
                                    <Grid gap={4}>
                                        <GridItem>
                                            <Menubar
                                                items={[
                                                    ...(goal ? [{ type: 'toggle', textOn: "Show", textOff: "Hide", checked: showGoal, onClick: () => setShowGoal(!showGoal) }] : []),
                                                    { type: 'button', text: 'Set targets', onClick: () => handleSidebar('open') },
                                                ]}
                                            />
                                        </GridItem>
                                        <GridItem>
                                            <OverviewL0 data={data} loading={loading} goal={showGoal && goal} score={score} />
                                        </GridItem>
                                        <GridItem>
                                            <OverviewL1
                                                alignment={alignment}
                                                data={data}
                                                loading={loading}
                                                goal={showGoal && goal}
                                                rolesAlignment={(!loading && data) && Object.values(roles).reduce((prev, curr) => prev + (curr.count > 0 ? 1 : 0), 0) > 1}
                                                getDataOnFilter={getDataOnFilter}
                                                markets={selectedMarkets}
                                                departments={selectedDepartments}
                                            />
                                        </GridItem>
                                    </Grid>
                                </div>
                            </div>
                        </>
                    )}
                    {(!page || page === 'analysis') && (
                        <div className='Overview'>
                            <Grid
                                gap={4}
                                gridTemplateColumns="repeat(2, 1fr)"
                                p={8}
                            >
                                <GridItem colSpan={2}>
                                    <TransitionImpactContainer user={user} survey={selectedSurvey?.id} markets={selectedMarkets} departments={selectedDepartments}>
                                        <TransitionImpact
                                            alignment={alignment}
                                        />
                                    </TransitionImpactContainer>
                                </GridItem>
                                <GridItem>
                                    <TimeScaleNetZeroContainer user={user} survey={selectedSurvey?.id} markets={selectedMarkets} departments={selectedDepartments}>
                                        <BarChartDataModel
                                            alignment={alignment}
                                        />
                                    </TimeScaleNetZeroContainer>
                                </GridItem>
                                <GridItem>
                                    <OffsettingContainer user={user} survey={selectedSurvey?.id} markets={selectedMarkets} departments={selectedDepartments}>
                                        <BarChartDataModel
                                            alignment={alignment}
                                        />
                                    </OffsettingContainer>
                                </GridItem>
                                <GridItem>
                                    <CarbonPriceContainer user={user} survey={selectedSurvey?.id} markets={selectedMarkets} departments={selectedDepartments}>
                                        <CarbonPrice
                                            alignment={alignment}
                                        />
                                    </CarbonPriceContainer>
                                </GridItem>
                            </Grid>
                        </div>
                    )}
                </>
            ) : (
                <SkeletonDashboard />
            )}
        </>
    )
};

export default Overview;