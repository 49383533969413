import { useEffect, cloneElement } from "react";
import { useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { getCompanyGoals, getCompanySurveys, getDistribution, getSurvey, getSurveyRoles, getTypeform, getTypeformResponseById } from "../api";
import constants from "../constants";
import { colorsArray } from "../styles/colors";

const DataContainer = (props) => {
    const { user, children } = props;
    const { company } = useParams();
    const location = useLocation();
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState(null);
    const [goals, setGoals] = useState([]);
    const [roles, setRoles] = useState(null);
    const [distribution, setDistribution] = useState(null);
    const [markets, setMarkets] = useState(null);
    const [departments, setDepartments] = useState(null);
    const [fetchedOn, setFetchedOn] = useState('roles');
    const [surveys, setSurveys] = useState(null);
    const [selectedSurvey, setSelectedSurvey] = useState(null);
    const [selectedMarkets, setSelectedMarkets] = useState([]);
    const [selectedDepartments, setSelectedDepartments] = useState([]);

    useEffect(() => {
        if (user && user.__raw) {
            setLoading(true);
            const role = user[constants.namespace.role];
            switch (role) {
                case 'admin':
                case 'super user':
                    getCompanySurveys(user.__raw, company).then(async res => {
                        const surveys = res.data;
                        const queryParams = new URLSearchParams(location.search);
                        const surveyId = queryParams.get('survey');
                        setSurveys(surveys.filter(survey => survey.status === 'SENT'));
                        if (surveyId) {
                            const selected = surveys.find(survey => survey.id === surveyId);
                            setSelectedSurvey(selected);
                            getSurveyData(surveyId);
                        } else {
                            setSelectedSurvey(surveys[0]);
                            addQueryParam(surveys[0].id);
                            getSurveyData(surveys[0].id);
                        }
                    });
                    break;
                default:
                    setName(user.nickname[0].toUpperCase() + user.nickname.slice(1));
                    getTypeformResponseById(user.__raw, user.typeformId, user.typeformToken)
                        .then(res => {
                            setData(res);
                            setLoading(false);
                        });
            }
        }
    }, [user, company]);

    const getDataOnFilter = async (on, oldData = data, newMarkets = selectedMarkets, newDepartments = selectedDepartments) => {
        const newData = await getTypeform(selectedSurvey.id, user.__raw, on, Object.values(newMarkets), Object.values(newDepartments));
        setFetchedOn(on);
        setData({
            ...oldData,
            children: Object.values(oldData.children).reduce((prevL0, currL0) => ({
                ...prevL0,
                [currL0.id]: {
                    ...currL0,
                    children: Object.values(currL0.children).reduce((prevL1, currL1) => ({
                        ...prevL1,
                        [currL1.id.split('-')[1]]: {
                            ...currL1,
                            [on]: newData.children[currL0.id].children[currL1.id.split('-')[1]][on],
                            children: Object.values(currL1.children).reduce((prevL2, currL2) => ({
                                ...prevL2,
                                [currL2.id.split('-')[2]]: {
                                    ...currL2,
                                    [on]: newData.children[currL0.id].children[currL1.id.split('-')[1]].children[currL2.id.split('-')[2]][on],
                                }
                            }), {})
                        }
                    }), {})
                }
            }), {})
        });
    }

    const getData = async (type, value) => {
        if (type === 'survey') {
            const newSurvey = surveys.find(survey => survey.id === value);
            setSelectedSurvey(newSurvey);
            addQueryParam(newSurvey.id);
            await getSurveyData(value);
            setFetchedOn('roles');
        } else if (type === 'markets') {
            let data;
            let newMarkets;
            if (value.length > 0) {
                newMarkets = value;
                setSelectedMarkets(value.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}));
            } else {
                newMarkets = Object.values(markets);
                setSelectedMarkets(markets);
            }
            data = await getTypeform(selectedSurvey.id, user.__raw, 'roles', newMarkets, Object.values(selectedDepartments));
            if (fetchedOn !== 'roles') {
                getDataOnFilter(fetchedOn, data, newMarkets, Object.values(selectedDepartments));
            } else {
                setData(data);
            }
            const dataRoles = await getSurveyRoles(selectedSurvey.id, user.__raw, newMarkets, Object.values(selectedDepartments));
            setRoles(dataRoles);
        } else if (type === 'departments') {
            let data;
            let newDepartments;
            if (value.length > 0) { // selection of departments
                newDepartments = value;
                setSelectedDepartments(value.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}));
            } else { // all departments
                newDepartments = Object.values(departments);
                setSelectedDepartments(departments);
            }
            data = await getTypeform(selectedSurvey.id, user.__raw, 'roles', Object.values(selectedMarkets), Object.values(newDepartments));
            if (fetchedOn !== 'roles') {
                getDataOnFilter(fetchedOn, data, Object.values(selectedMarkets), newDepartments);
            } else {
                setData(data);
            }
            const dataRoles = await getSurveyRoles(selectedSurvey.id, user.__raw, Object.values(selectedMarkets), newDepartments);
            setRoles(dataRoles);
        }
    };

    const getSurveyData = async (surveyId) => {
        setLoading(true);
        const survey = await getSurvey(user.__raw, surveyId);
        if (survey.status === 200) {
            const { name, markets, departments } = survey.data;
            setName(name);
            if ((user[constants.namespace.role] === 'super user' && user.companyId === company) || user[constants.namespace.role] === 'admin') {
                const dataRoles = await getSurveyRoles(surveyId, user.__raw, markets, departments);
                const dataDistribution = await getDistribution(surveyId, user.__raw);
                const data = await getTypeform(surveyId, user.__raw, fetchedOn, markets, departments);
                const goals = await getCompanyGoals(user.__raw, company);
                setRoles(dataRoles);
                const newMarkets = markets.reduce((prev, curr, index) => ({ ...prev, [curr.id]: { ...curr, color: colorsArray[index % colorsArray.length] } }), {});
                setMarkets(newMarkets);
                setSelectedMarkets(newMarkets);
                const newDepartments = departments.reduce((prev, curr, index) => ({ ...prev, [curr.id]: { ...curr, color: colorsArray[index % colorsArray.length] } }), {});
                setDepartments(newDepartments);
                setSelectedDepartments(newDepartments);
                setDistribution(dataDistribution);
                setData(data);
                setGoals(goals.data);

                setLoading(false);
            }
        }
    };

    const addQueryParam = value => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set('survey', value);
        const newUrl = `${window.location.pathname}?${queryParams.toString()}`;
        window.history.pushState({}, '', newUrl);
    };

    return cloneElement(children, { selectedSurvey, data, user, roles, distribution, loading, name, goals, getDataOnFilter, getData, markets, selectedMarkets, departments, selectedDepartments, surveys });
}

export default DataContainer;