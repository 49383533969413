import React, { useEffect, useState } from "react";
import Block from "../../Block/Block";
import Alignment from "../../Alignment/Alignment";
import BarChart from "../../BarChart/BarChart";
import colors from "../../../styles/colors";
import ClusterLine from "../../visualization/ClusterLine/ClusterLine";
import { ReactComponent as Person } from '../../../assets/icons/person.svg';

const CarbonPrice = ({ ready, data, getData, alignment }) => {
    const [on, setOn] = useState({ name: 'Show average', value: 'average' });
    const choices = {
        yes: { label: 'Yes', colorCode: colors["color__green-light"], color: 'green-light' },
        no: { label: 'No', colorCode: colors.color__red, color: "red" },
        dk: { label: "I don't know", colorCode: colors.color__orange, color: 'orange' }
    };

    useEffect(() => {
        if (ready) getData('average');
    }, [ready]);

    const handleOnChangeAlignment = target => {
        setOn(target);
        getData(target.value);
    }

    return (
        <Block
            color="white"
            title="Carbon pricing"
        >
            <Alignment
                isSelected={on.name}
                onClick={handleOnChangeAlignment}
                legends={on.value !== 'average' && alignment[on.value]}
                icon={on.value === 'roles' && (props => <Person {...props} />)}
            />
            <h4>Do we have an internal carbon price in our organisation?</h4>
            <BarChart
                isStacked={true}
                prefix="%"
                numberOnTop={true}
                data={data['average'].carbonData && Object.entries(choices).map(([key, value]) => {
                    const choice = data['average'].carbonData.find(d => d.choice === key);
                    if (!choice) return {
                        id: key,
                        label: value.label,
                        score: 0,
                        data: []
                    };
                    return {
                        label: value.label,
                        data: [{
                            ...value,
                            colorCode: !!on.value && on.value !== 'average' ? undefined : value.colorCode,
                            score: choice.percentage,
                            data: !!on.value && on.value !== 'average' ? data[on.value]?.carbonData
                                ?.filter(item => item.choice === choice.choice && alignment[on.value][item.id.toLowerCase()])
                                .map(item => ({
                                    id: item.id,
                                    score: item.percentage,
                                    colorCode: colors[`color__${alignment[on.value][item.id.toLowerCase()]?.color}`],
                                    color: alignment[on.value][item.id.toLowerCase()].color,
                                    label: alignment[on.value][item.id.toLowerCase()].name,
                                })) : []
                        }]
                    };
                })}
            />
            <h4>Perceived carbon price level ({data.average.price?.length} respondents)</h4>
            {data.average.price && <ClusterLine data={data.average.price || []} prefix="€" />}
        </Block>
    )
};

export default CarbonPrice;