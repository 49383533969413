import React from "react";
import './Descriptions.scss';

const Descriptions = ({ descriptions }) => {
    return (
        <div className="Descriptions">
            {descriptions.map(description => (
                <p key={description}>{description} <b>({description[0]})</b></p>
            ))}
        </div>
    )
}

export default Descriptions;