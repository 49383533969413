import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import Steps from "../../Steps/Steps";
import UsersList from "../../models/UsersList/UsersList";
import ButtonAction from "../../Button/ButtonAction";
import CreateSurveyInfoForm from "./CreateSurveyInfoForm";
import HomeLink from "../../navigation/HomeLink/HomeLink";
import CreateSurveySummary from "./CreateSurveySummary";
import CreateSurveySegmentation from "./CreateSurveySegmentation";
import PopupSmall from "../../PopupSmall/PopupSmall";
import './CreateSurvey.scss';
import Loader from "../../Loader/Loader";
import { Box } from "@chakra-ui/react";

const CreateSurvey = ({ status, saving, saveSurvey, createSurvey, surveyName, surveyTitle, surveyIntro, surveyTitleTemp, surveyIntroTemp, markets, departments, alias, onChangeSegmentation, step, loading, onAddContacts, recipients, updateRecipient, deleteRecipients, discardChanges, handleSurveyInfo }) => {
    let location = useLocation();
    const navigate = useNavigate();
    const { company } = useParams();
    const [users, setUsers] = useState({});
    const [creating, setCreating] = useState(false);
    const [forward, setForward] = useState({
        next: 'segmentation',
        disabled: true
    });
    const [backward, setBackward] = useState(null);
    const [openAddUsers, setOpenAddUsers] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [step]);

    useEffect(() => {
        switch (step) {
            case 'info':
                setForward({ next: 'segmentation', disabled: !(surveyName !== '' && surveyTitleTemp.replaceAll('<br>', '') !== '' && surveyIntroTemp.replaceAll('<br>', '') !== '') });
                setBackward(null);
                break;
            case 'segmentation':
                if (surveyName === '' || surveyTitle === '' || surveyIntro === '') {
                    navigate(newLocation('info'));
                } else {
                    setForward({ next: 'recipients', disabled: markets.length === 0 });
                    setBackward('info');
                }
                break;
            case 'recipients':
                if (surveyName === '' || surveyTitle === '' || surveyIntro === '') {
                    navigate(newLocation('info'));
                } else {
                    setForward({
                        next: 'summary',
                        disabled: Object.values(recipients).length === 0 || Object.values(recipients).filter(r => !(r.market && r.market.name) || (departments.length > 0 && !(r.department && r.department.name)) || !(r.role && r.role.name)).length > 0
                    });
                    setBackward('segmentation');
                }
                break;
            case 'summary':
                if (!loading && (surveyName === '' || surveyTitle === '' || surveyIntro === '')) {
                    navigate(newLocation('info'));
                } else if (Object.values(recipients).length === 0) {
                    navigate(newLocation('recipients'));
                } else {
                    setForward({ next: null, disabled: true });
                    setBackward('recipients');
                }
                break;
            default:
                return;
        }
    }, [step, surveyName, surveyTitle, surveyTitleTemp, surveyIntroTemp, surveyIntro, markets, departments, recipients]);

    const selectedUsers = newUsers => {
        setUsers(newUsers);
    }

    const newLocation = step => `${location.pathname.replace(/[^/]*$/, step)}`;
    
    if (loading) {
        return (
            <Box pos="absolute" w="100%" top="40%" transform="translateY(-50%)">
                <Loader />
            </Box>
        )
    }

    return (
        <div className="CreateSurvey">
            <Grid gridRowGap="2rem" margin="0 1rem">
                <GridColumn size={12} noPadding={true}>
                    <HomeLink company={company} />
                </GridColumn>
                <GridColumn size={6} noPadding={true}>
                    <h1>Create Survey</h1>
                </GridColumn>
                <GridColumn size={6} align="center" noPadding={true}>
                    <div className="CreateSurvey__align--right">
                        <Link className={`CreateSurvey__text--small ${saving ? 'CreateSurvey__disabled' : ''}`} onClick={discardChanges}>Discard changes</Link>
                        <ButtonAction type="primary" text={saving ? "...Saving" : "Save changes"} disabled={saving} onClick={() => saveSurvey(surveyName, surveyTitle, surveyIntro, Object.values(users).filter(user => user.selected))} />
                    </div>
                </GridColumn>
                <GridColumn size={12}>
                    <div className="CreateSurvey__steps">
                        <Steps steps={[
                            { id: 'info', title: 'Survey Info', status: step === 'info' ? 'active' : 'complete' },
                            { id: 'segmentation', title: 'Segmentation', status: step === 'segmentation' ? 'active' : step === 'info' ? 'uncomplete' : 'complete' },
                            { id: 'recipients', title: 'Recipients', status: step === 'recipients' ? 'active' : step === 'info' || step === 'segmentation' ? 'uncomplete' : 'complete' },
                            { id: 'summary', title: 'Summary', status: step === 'summary' ? 'active' : 'uncomplete' }
                        ]} />
                    </div>
                </GridColumn>
                <GridColumn size={12} noPadding={true}>
                    {/* STEP: INFO */}
                    <div className={`CreateSurvey__stepContainer CreateSurvey__stepContainer${step === 'info' ? '--visible' : '--hidden'}`}>
                        {step === 'info' && (
                            <>
                                <h2 className="CreateSurvey__subtitle">Survey Info</h2>
                                <div>
                                    <CreateSurveyInfoForm status={status} name={surveyName} title={surveyTitle} intro={surveyIntro} previewValue={{ title: surveyTitleTemp, intro: surveyIntroTemp }} handleOnChange={handleSurveyInfo} />
                                </div>
                            </>
                        )}
                    </div>
                    {/* STEP: SEGMENTATION */}
                    <div className={`CreateSurvey__stepContainer CreateSurvey__stepContainer${step === 'segmentation' ? '--visible' : '--hidden'}`}>
                        {step === 'segmentation' && (
                            <>
                                <h2 className="CreateSurvey__subtitle">Segmentation</h2>
                                <CreateSurveySegmentation markets={markets} departments={departments} alias={alias} onChange={onChangeSegmentation} />
                            </>
                        )}
                    </div>
                    {/* STEP: RECIPIENTS */}
                    <div className={`CreateSurvey__stepContainer CreateSurvey__stepContainer${step === 'recipients' ? '--visible' : '--hidden'}`}>
                        <h2 className="CreateSurvey__subtitle">Recipients</h2>
                        <UsersList users={recipients} alias={alias} selectedUsers={selectedUsers} openAddUsers={openAddUsers} handleOpenAddUsers={open => setOpenAddUsers(open)} selectedLabel="recipients" markets={markets} departments={departments} addContacts={contacts => onAddContacts(contacts)} updateUser={updateRecipient} deleteSelectedUsers={deleteRecipients} />
                    </div>
                    {/* STEP: SUMMARY */}
                    <div className={`CreateSurvey__stepContainer CreateSurvey__stepContainer${step === 'summary' ? '--visible' : '--hidden'}`}>
                        <h2 className="CreateSurvey__subtitle">Summary</h2>
                        {step === 'summary' && (
                            <CreateSurveySummary alias={alias} users={Object.values(recipients)} name={surveyName} title={surveyTitleTemp} intro={surveyIntroTemp} hasDepartment={departments.length > 0} />
                        )}
                        <div className="CreateSurvey__stepExplanation">
                            <PopupSmall>
                                <ButtonAction type="question" />
                            </PopupSmall>
                        </div>
                    </div>
                </GridColumn>
                <GridColumn size={12} noPadding={true}>
                    <div className="CreateSurvey__align--right">
                        {backward && <Link to={newLocation(backward)}><ButtonAction type="secondary" text="Back" /></Link>}
                        {forward.next && <Link to={newLocation(forward.next)}><ButtonAction type="primary" text="Next" disabled={forward.disabled} /></Link>}
                        {step === 'summary' && <ButtonAction type="primary" text="Create and send" disabled={creating} onClick={() => {
                            setCreating(true);
                            createSurvey();
                        }} />}
                    </div>
                </GridColumn>
            </Grid>
        </div >
    )
}

export default CreateSurvey;