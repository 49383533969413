import React from "react";
import "./Tag.scss";

const Tag = ({ tag, onClick }) => (
    <div className="Tag" onClick={e => {
        e.preventDefault();
        e.stopPropagation();
    }}>
        {tag.name}
        <div className="Tag__cross" onClick={() => onClick(tag)}></div>
    </div>);

export default Tag;