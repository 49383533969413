import React, { useEffect, useState } from "react";
import Block from "../Block/Block";
import ButtonAction from "../Button/ButtonAction";
import InputField from "../InputField/InputField";
import TextArea from "../TextArea/TextArea";
import './Popup.scss';

const Popup = ({ ref, title, fields, onSubmit, onCancel, error, successMessage, color, cancelType, submitType, submitText, submitDisabled, extraActionButtons }) => {
    const [newFields, setFields] = useState([]);

    useEffect(() => {
        if (fields) {
            setFields(fields.map(field => ({ ...field })));
        }
    }, [fields]);

    const handleOnChange = (index, value) => {
        setFields([...newFields.slice(0, index), { ...newFields[index], value }, ...newFields.slice(index + 1)]);
    };

    const getField = (field, index) => {
        switch (field.type) {
            case 'component':
                return field.value;
            case 'input':
                return <InputField backgroundColor={color} name={field.name} onChange={value => handleOnChange(index, value)} value={field.value} placeholder={field.name} />
            case 'textarea':
                return <TextArea color={color} name={field.name} onChange={value => {
                    handleOnChange(index, value);
                    field.onChange(value);
                }} value={field.value} placeholder={field.name} />
            default:
                return <p>{field.value}</p>
        }
    }

    return (
        <div ref={ref} className={`Popup${color ? `--${color}` : ''}`}>
            <div className="Popup__wrapper">
                <Block title={title} color={color}>
                    <div className="Popup__fields">
                        {newFields.length > 0 && newFields.map((field, index) => (
                            <div key={field.name} className={`Popup__field ${field.overflow ? 'Popup__field--overflow' : ''}`}>
                                {getField(field, index)}
                            </div>
                        ))}
                    </div>
                    <div className="Popup__actions">
                        {onSubmit && <div className="Popup__button">
                            <p className="Popup__actions--message">{error}</p>
                            <p className="Popup__actions--message">{successMessage}</p>
                            <ButtonAction onClick={() => onSubmit(newFields)} type={submitType || 'save'} text={submitText} disabled={submitDisabled} />
                        </div>}
                        {extraActionButtons && extraActionButtons.map(button => (
                            <div className="Popup__button">
                                <ButtonAction onClick={button.action} type={button.type} text={button.text} />
                            </div>
                        ))}
                        {onCancel && <div className="Popup__button">
                            <ButtonAction onClick={onCancel} type={cancelType || 'cancel'} />
                        </div>}
                    </div>
                </Block>
            </div>
        </div>
    )
};

export default Popup;