import { cloneElement, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { sanitize } from 'isomorphic-dompurify';
import { addSurvey, createForm, getCompanyById, getSurvey as getSurveyFromDB, updateSurvey } from "../api";
import constants, { dynamicConstants } from "../constants";

const CreateSurveyContainer = ({ children, user }) => {
    const { company, step, survey } = useParams();
    const navigate = useNavigate();
    const [surveyName, setSurveyName] = useState("");
    const [surveyTitle, setSurveyTitle] = useState("");
    const [surveyIntro, setSurveyIntro] = useState("");
    const [surveyTitleTemp, setSurveyTitleTemp] = useState("");
    const [surveyIntroTemp, setSurveyIntroTemp] = useState("");
    const [loading, setLoading] = useState(true);
    const [markets, setMarkets] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [alias, setAlias] = useState(constants.roles);
    const [recipients, setRecipients] = useState({});
    const [saving, setSaving] = useState(false);
    const [status, setStatus] = useState(null);

    console.log('survey', survey)

    useEffect(() => {
        if (user && user.__raw) {
            if (survey) { // edit mode
                setLoading(true);
                getSurvey().then(() => setLoading(false));
            } else if (!survey) {
                getCompanyById(user.__raw, company).then(res => {
                    if (res.status === 200) {
                        setSurveyIntro(dynamicConstants(res.name).sample.survey.description);
                        setSurveyIntroTemp(dynamicConstants(res.name).sample.survey.description);
                        setLoading(false);
                    }
                });
                setSurveyTitle(constants.sample.survey.title);
                setSurveyTitleTemp(constants.sample.survey.title);
            }
        }
    }, [user, company, survey]);

    const getSurvey = async () => {
        const dataSurvey = await getSurveyFromDB(user.__raw, survey, true);
        if (dataSurvey.status === 200) {
            setSurveyName(dataSurvey.data.name);
            setSurveyTitle(dataSurvey.data.title);
            setSurveyIntro(dataSurvey.data.intro);
            setSurveyTitleTemp(dataSurvey.data.title);
            setSurveyIntroTemp(dataSurvey.data.intro);
            setMarkets(dataSurvey.data.markets);
            setDepartments(dataSurvey.data.departments);
            setRecipients(dataSurvey.data.recipients);
            setStatus(dataSurvey.data.status);
            setAlias(alias => ({
                executive: {
                    ...alias.executive,
                    alias: dataSurvey.data.executiveAlias
                },
                senior: {
                    ...alias.senior,
                    alias: dataSurvey.data.seniorAlias
                },
                operational: {
                    ...alias.operational,
                    alias: dataSurvey.data.operationalAlias
                }
            }))
        };
    }

    const saveSurvey = async (name, title, intro, recipients) => {
        setSaving(true);
        const res = await saveToDB(name, title, sanitize(intro), recipients);
        setSaving(false);
        if (res.status === 200) {
            const newSurvey = res.data;
            setSurveyName(newSurvey.name);
            setSurveyTitle(newSurvey.title);
            setSurveyIntro(newSurvey.intro);
            setSurveyTitleTemp(newSurvey.title);
            setSurveyIntroTemp(newSurvey.intro);
            setRecipients(newSurvey.recipients);
            setAlias(alias => ({
                executive: {
                    ...alias.executive,
                    alias: newSurvey.executiveAlias
                },
                senior: {
                    ...alias.senior,
                    alias: newSurvey.seniorAlias
                },
                operational: {
                    ...alias.operational,
                    alias: newSurvey.operationalAlias
                }
            }))
            if (!survey) {
                navigate(`/company/${company}/update/survey/${res.data.id}/${step}`);
            }
        }
    }

    const saveToDB = () => {
        if (survey) {
            return updateSurvey(user.__raw, { id: survey, companyId: company, name: surveyName, title: surveyTitleTemp, intro: surveyIntroTemp, markets, departments, alias, recipients });
        } else {
            return addSurvey(user.__raw, { companyId: company, name: surveyName, title: surveyTitleTemp, intro: surveyIntroTemp, markets, departments, alias, recipients });
        }
    }

    const discardChanges = () => {
        if (survey) {
            setSurveyTitle('');
            setSurveyIntro('');
            getSurvey().then(() => setLoading(false));
        }
    }

    const createSurvey = async () => {
        /*saveToDB(surveyName, surveyTitleTemp, surveyIntroTemp, recipients).then(res => {
            if (res.status === 200) {
                const dataSurvey = res.data;
                createForm(user.__raw, dataSurvey.id).then(res => {
                    if (res.status === 200) {
                        navigate(`/company/${company}/admin`);
                    }
                });
            }
        });*/
        if (survey) {
            createForm(user.__raw, survey).then(res => {
                if (res.status === 200) {
                    navigate(`/company/${company}/admin`);
                }
            });
        }
    }

    const onChange = (arr, item, index) => [...[...arr].splice(0, index), item, ...[...arr].splice(index + 1, arr.length)]
    const onDelete = (arr, index) => [...[...arr].splice(0, index), ...[...arr].splice(index + 1, arr.length)];

    const handler = (type, arr, item = null, index, key) => {
        switch (type) {
            case 'change':
            case 'cancel':
                return onChange(arr, item, index);
            case 'save': {
                const newRecpients = Object.values(recipients).reduce((prev, curr) => {
                    if (curr[key] && curr[key].id === item.id) {
                        return { ...prev, [curr.id]: { ...curr, [key]: item } }
                    }
                    return { ...prev, [curr.id]: curr };
                }, {});
                setRecipients(newRecpients);
                return onChange(arr, item, index);
            }
            case 'delete': {
                const newRecpients = Object.values(recipients).reduce((prev, curr) => {
                    if (!!curr[key] && curr[key].id === item.id) {
                        return { ...prev, [curr.id]: { ...curr, [key]: undefined } }
                    } else {
                        return { ...prev, [curr.id]: curr }
                    }
                }, {});
                setRecipients(newRecpients);
                return onDelete(arr, index);
            }
            default:
                return;
        }
    }

    const onChangeSegmentation = (type, name, item = null, index) => {
        switch (true) {
            case name === 'markets':
                setMarkets(handler(type, markets, item || markets[index], index, 'market'));
                break;
            case name === 'departments':
                setDepartments(handler(type, departments, item || departments[index], index, 'department'));
                break;
            case name === 'alias':
                setRecipients(recipients => Object.values(recipients).reduce((prev, curr) => ({
                    ...prev,
                    [curr.id]: { ...recipients[curr.id], role: curr.role?.id && item[curr.role.id] }
                }), {}));
                setAlias(item);
                break;
            default:
                return;
        }
    }

    const onAddContacts = contacts => {
        const newRecpients = { ...recipients };
        setRecipients(Object.values(contacts).reduce((prev, curr) => {
            const newObj = Object.values(prev).find(p => p.email === curr.email)
            if (newObj) {
                return {
                    ...prev,
                    [newObj.id]: { ...prev[newObj.id], ...curr, id: newObj.id, selected: false }
                }
            }
            return { ...prev, [curr.id]: { ...curr, selected: false } };
        }, newRecpients))
    }

    const updateRecipient = (recipient, key = null, oldValue = null, newValue = null) => {
        const newUnits = (unit) => {
            const oldIndex = oldValue && unit.findIndex(u => u.id === oldValue.id);
            const oldUnit = oldIndex && unit[oldIndex];
            const newIndex = unit.findIndex(u => u.id === newValue.id);
            const newUnit = unit[newIndex];
            unit = (oldUnit && onChange(unit, { ...oldUnit, recipients: oldUnit.recipients.filter(r => r.id !== recipient.id) }, oldIndex)) || unit;
            return onChange(unit, { ...newUnit, recipients: [...(newUnit.recipients || []), recipient] }, newIndex);
        }
        switch (key) {
            case 'market':
                setMarkets(newUnits([...markets]));
                break;
            case 'department':
                setDepartments(newUnits([...departments]));
                break;
            default:
                break;
        }
        setRecipients(recipients => ({
            ...recipients,
            [recipient.id]: recipient
        }));
    };

    const deleteRecipients = deletedRecipients => {
        setRecipients(recipients => Object.values(recipients).reduce((prev, curr) => {
            if (deletedRecipients[curr.id]) return prev;
            return { ...prev, [curr.id]: curr };
        }, {}))
    }

    const handleSurveyInfo = (key, value) => {
        if (key === 'name') {
            setSurveyName(value);
        } else if (key === 'title') {
            setSurveyTitleTemp(value);
        }
        else {
            setSurveyIntroTemp(value);
        }
    }

    return cloneElement(children, { status, saving, saveSurvey, createSurvey, surveyName, surveyTitle, surveyIntro, surveyTitleTemp, surveyIntroTemp, markets, departments, alias, onChangeSegmentation, loading, step, onAddContacts, recipients, updateRecipient, deleteRecipients, discardChanges, handleSurveyInfo });
}

export default CreateSurveyContainer;