import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "../Button/Button";
import { generateToken } from "../../helpers";
import './LoginButton.scss';

const LoginButton = ({ typeformId, company }) => {
    const { loginWithRedirect, isAuthenticated, logout } = useAuth0();

    return !isAuthenticated ?
        <Button onClick={() => loginWithRedirect({ scope: 'read:current_user', typeformToken: generateToken(), typeformId, company })}>Sign in</Button> :
        <div className="LoginButton__logout" onClick={() => logout({ returnTo: window.location.origin })}>Log out</div>
}

export default LoginButton;