import React from "react";
import { Flex, Text } from "@chakra-ui/react";
import Dropdown from "../Dropdown/Dropdown";
import './Alignment.scss';

const Alignment = ({ onClick, isSelected, legends, icon }) => (
    <div className="Alignment">
        <Dropdown
            label="Alignment"
            color="gray-5"
            onChange={onClick}
            selected={isSelected}
            options={[{ name: 'Show average', value: null }, { name: 'Markets alignment', value: 'markets' }, { name: 'Departments alignment', value: 'departments' }, { name: 'Roles alignment', value: 'roles' }]}
        />
        <Flex gap="1rem" flexWrap="wrap">
            {legends && Object.values(legends).map(legend => (
                <div className="Alignment__text">
                    {icon ? (
                        icon({
                            className: `Alignment__icon--${legend.color}`
                        })
                    ) : (
                        <Flex
                            className={`Alignment__legend--${legend.color}`}
                            borderRadius="100%"
                            h="16px"
                            w="16px"
                            minW="16px"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Text color="black" fontWeight="bold" fontSize="xx-small">{legend.name[0]}</Text>
                        </Flex>
                    )}
                    <span>{legend.name}</span>
                </div>
            ))}
        </Flex>
    </div >
);

export default Alignment;