import React, { useEffect, useState } from "react";
import Block from "../Block/Block";
import Table from "../Table/Table";
import ButtonAction from "../Button/ButtonAction";
import Popup from "../Popup/Popup";
import Button from "../Button/Button";
import { addCompany, deleteCompany, getCompanies } from "../../api";

const CompaniesBlock = ({ token }) => {
    const [companies, setCompanies] = useState([]);
    const [openCreateCompany, setOpenCreateCompany] = useState(false);
    const [createError, setCreateError] = useState(false);
    const [selectedCompany, setSelectedCompany] = useState(null);

    useEffect(() => {
        if (token) {
            getCompanies(token).then(res => {
                setCompanies(res);
            });
        }
    }, [token]);

    const handleOnSubmitNewCompany = company => {
        setCreateError(false);
        const { value } = company;
        if (token && value !== '') {
            addCompany(token, value).then(res => {
                if (res.status === 200) {
                    setOpenCreateCompany(false);
                    setCompanies([...companies, res.data]);
                } else {
                    setCreateError(true);
                }
            })
        }
    }

    const handleDeleteCompany = () => {
        deleteCompany(token, selectedCompany.id).then(res => {
            if (res.status === 200) {
                const index = companies.findIndex(company => company.id === selectedCompany.id);
                setCompanies([...companies.slice(0, index), ...companies.slice(index + 1)]);
                setSelectedCompany(null);
            }
        })
    }

    return (
        <Block col='1' title="Companies" color="white">
            <Table
                headers={[{ name: 'Company' }, { name: '', width: '0px' }]}
                data={Object.values(companies).map(company => ({
                    id: company.id,
                    columns: [
                        { id: 1, type: 'link', label: company.name, url: `/company/${company.id}/admin` },
                        { id: 'delete', label: <ButtonAction type="delete--small" onClick={() => setSelectedCompany(company)} /> }
                    ]
                }))}
            />
            {selectedCompany && <Popup
                title={`Are you sure you want to delete ${selectedCompany.name}?`}
                fields={[{ id: 'text', value: 'This is a permanent action and all data connected to the company will be deleted.' }]}
                submitText="Delete"
                onCancel={() => setSelectedCompany(null)}
                onSubmit={handleDeleteCompany}
                color="white"
            />}
            <div className="Admin__right">
                <div className="Admin__button">
                    <Button onClick={() => setOpenCreateCompany(true)}><div className="Admin__buttonInner">Create company</div></Button>
                </div>
                {openCreateCompany && <Popup
                    title="Name of the company"
                    fields={[{ type: 'input', name: 'Company name' }]}
                    submitText="Create"
                    onSubmit={company => handleOnSubmitNewCompany(company[0])}
                    onCancel={() => setOpenCreateCompany(false)}
                    error={createError && "Sorry, try again!"}
                    color="white"
                />}
            </div>
        </Block>
    )
}

export default CompaniesBlock;