import React from "react";
import { sortedRoles } from "../../helpers";
import Grid from "../gridSystem/Grid/Grid";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import { ReactComponent as Person } from '../../assets/icons/person.svg';
import Descriptions from "../Descriptions/Descriptions";
import './Roles.scss';
import colors from "../../styles/colors";

const Roles = ({ alias, data }) => (
    <Grid>
        <GridColumn height="100%" size={3} mobileSize={12} noPadding={true} >
            <div className="Roles__box--dashed Roles--mobileBottom">
                <p className="Roles__title Roles--mobileColon"><b>Total participants</b></p>
                <p className="Roles__number"><b>{data.total}</b></p>
            </div>
        </GridColumn>
        {sortedRoles.map((role, index) => (
            <GridColumn key={role.name} size={3} mobileSize={4} noPadding={true} >
                <div className={`Roles__box${index !== sortedRoles.length - 1 ? '--dashed' : ''}`}>
                    <div className="Roles__desktop">
                        <p className="Roles__title">{(alias && alias[role.id]) || role.name}</p>
                        <p className="Roles__number"><Person fill={colors["color__gray-2"]} height="26" /> {data[role.name]}</p>
                    </div>
                    <div className="Roles__mobile">
                        <p className="Roles__title Roles__center"><Person fill={colors["color__gray-2"]} height="26" />{role.name[0]}</p>
                        <p className="Roles__number Roles__center">{data[role.name]}</p>
                    </div>
                </div>
            </GridColumn>
        ))}
        <GridColumn size={12} displayOnly={'mobile'}>
            <Descriptions descriptions={sortedRoles.map(role => role.name)} />
        </GridColumn>
    </Grid>
)

export default Roles;