import React from "react";
import './Toggle.scss';

const Toggle = ({ textOn, textOff, onClick, isChecked }) => (
    <div className="Toggle">
        {textOff && <span>{textOff}</span>}
        <label className="Toggle__switch" onChange={e => onClick(e.target.checked)}>
            <input type="checkbox" checked={isChecked} readOnly={true} />
            <span className="Toggle__slider"></span>
        </label>
        {textOn && <span>{textOn}</span>}
    </div>
)

export default Toggle;