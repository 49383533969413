import { cloneElement, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getCompany } from "../api";

const CompanyContainer = ({ user, children }) => {
    const { company } = useParams();
    const [thisCompany, setCompany] = useState({});
    const [error, setError] = useState(false);

    useEffect(() => {
        if (user && user.__raw) {
            getCompany(user.__raw, company).then(res => {
                if (res.status === 200 && res.data) {
                    setCompany(res.data);
                } else {
                    setError(true);
                }
            })
        }
    }, [company, user]);

    return cloneElement(children, { user, company: thisCompany, error });
}

export default CompanyContainer;