import { AiOutlineFire } from "react-icons/ai";
import { BsLightning, BsMegaphone } from "react-icons/bs";
import constants from "./constants";
import colors, { colorsArray } from "./styles/colors";
import { ReactComponent as Person } from './assets/icons/person.svg';

export const roundTo10Decimals = number => Math.round(number * 10) / 10;

export const getIcon = (type) => {
    switch (type) {
        case 'motivation':
        case 'willingness':
            return <AiOutlineFire color={colors.color__black} />
        case 'capacity':
        case 'readiness':
            return <BsLightning color={colors.color__black} />
        case 'disclosure':
        case 'openness':
            return <BsMegaphone color={colors.color__black} />
        default:
            return null;
    }
}

export const responsesDistribution = (data, keys, children) => {
    let arr = Object.entries(data).filter(([key]) => Object.keys(keys).indexOf(key) > -1).reduce((acc, [key, value]) => {
        return {
            ...acc,
            [key]: {
                'Total': {
                    value: Math.round(value / data.total * 100) + '%',
                    order: 0,
                    key: 'Total'
                },
                'untitled': {
                    order: 1,
                    key: '',
                    value: keys[key].con,
                    left: true
                }
            }
        }
    }, {})

    return Object.values(data[children]).reduce((prev, curr, index) => Object.keys(keys).reduce((p, c) => ({ ...p, [c]: { ...p[c], [curr.role]: { value: curr[c] ? Math.round(curr[c] / curr.total * 100) + '%' : 0 + '%', order: index + 2, key: curr.role } } }), prev), arr);
}

export const generateToken = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const length = 7;
    let token = "";
    for (let i = 0; i < length; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        token += chars.substring(randomNumber, randomNumber + 1);
    }
    return token;
}

export const randomNumber = (callback) => setInterval(() => callback(score => score < 95 ? score + Math.floor(Math.random() * 5) + 1 : 100), 250);

export const breakString = s => {
    const stringArray = s.split(" ");
    return stringArray.map((substring, index) => <>{substring}{index + 1 !== stringArray.length ? <br /> : null}</>);
};

export const sortedRoles = Object.values(constants.roles).sort((roleA, roleB) => roleA.sort <= roleB.sort ? -1 : 0);

export const sortItems = (type, by, itemA, itemB) => {
    if (type === 'number' && itemA === '-') itemA = 0;
    if (type === 'number' && itemB === '-') itemB = 0;
    if (type !== 'number' && itemA === '-' && itemB !== '-') return by === 'asc' ? 1 : 1;
    if (type !== 'number' && itemB === '-' && itemA !== '-') return by === 'asc' ? -1 : -1;

    if ((type === 'number' && by === 'desc') || (type !== 'number' && by === 'asc')) {
        if (itemA <= itemB) return 1;
        return -1;
    } else {
        if (itemA >= itemB) return 1;
        return -1;
    }
}

export const createClusters = (arr) => {
    const treshhold = 50;
    let sortedArr = arr.sort((a, b) => a - b);
    let clusters = [];
    let currentCluster = [sortedArr[0]];

    const calcAvg = arr => arr.reduce((prev, curr) => prev + curr, 0) / arr.length;

    for (let i = 1; i < sortedArr.length; i++) {
        const curr = sortedArr[i];
        const prev = sortedArr[i - 1];

        if (curr - prev <= treshhold) {
            currentCluster = [...currentCluster, curr];
        } else {
            clusters = [...clusters, { avg: roundTo10Decimals(calcAvg(currentCluster)), data: currentCluster }];
            currentCluster = [curr];
        }
    }
    clusters = [...clusters, { avg: roundTo10Decimals(calcAvg(currentCluster)), data: currentCluster }];

    return clusters;
}

export const divideMainAndSecondary = (data, level, hasSecondary) => {
    let index = 0;
    let labels = {};

    return {
        ...data,
        children: Object.values(data.children).reduce((prev, curr) => ({
            ...prev,
            [curr.id]: {
                ...curr,
                data: [
                    {
                        id: 'main',
                        progress: Math.round(curr.score),
                        gradient: true,
                        color: level.color,
                        dotColor: level.color,
                        hasDot: true,
                        tooltip: true,
                        tail: hasSecondary && hasSecondary.key,
                        prefix: ''
                    },
                    ...(curr[hasSecondary && hasSecondary.key] ? [
                        {
                            id: 'secondary',
                            hasDot: true,
                            tooltip: true,
                            dotIcon: (hasSecondary && hasSecondary.key === 'roles') && <Person />,
                            dotLabel: hasSecondary && hasSecondary.key !== 'roles',
                            isHidden: !hasSecondary && hasSecondary.key,
                            ...curr[hasSecondary && hasSecondary.key] && Object.values(curr[hasSecondary && hasSecondary.key]).reduce((prevItems, currItem) => {
                                let color;
                                if (labels[currItem.id]) {
                                    color = labels[currItem.id].color;
                                } else {
                                    color = colorsArray[index % colorsArray.length];
                                    labels[currItem.name] = { label: currItem.id, color };
                                    index += 1;
                                }
                                return {
                                    progress: [...prevItems.progress, Math.round(currItem.score)],
                                    prefix: [...prevItems.prefix, (hasSecondary && hasSecondary.values[currItem.id.toLowerCase()].name) || currItem.name],
                                    color: [...prevItems.color, (hasSecondary && hasSecondary.key === 'roles') ? constants.roles[currItem.name.toLowerCase()].color : hasSecondary.values[currItem.id]?.color]
                                };
                            }, { prefix: [], progress: [], color: [] })
                        }
                    ] : [])
                ]
            }
        }), {})
    };
};