import React from "react";
import './InputField.scss';

const InputField = ({ name, type, min, max, value, placeholder, onChange, color, backgroundColor, readOnly, fontSize, fontWeight, align }) => (
    <div className="InputField">
        {!!placeholder && <p className="InputField__placeholder">{placeholder}</p>}
        <input className={`InputField__input ${placeholder ? '' : 'InputField__input--noPlaceholder'} InputField__input${backgroundColor ? `--${backgroundColor}` : ''} ${fontSize ? `InputField--${fontSize}` : ''} ${fontWeight ? `InputField--${fontWeight}` : ''}`} type={type || "text"} style={{ ...(align ? { textAlign: align } : {}), ...(color ? { color } : {}) }} name={name} value={value} min={min || null} max={max || null} readOnly={readOnly} onChange={e => onChange(e.target.value)} />
    </div>
)

export default InputField;