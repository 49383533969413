import { cloneElement, useEffect, useState } from "react";
import { getCarbonPrice } from "../api";
import constants from "../constants";

const CarbonPriceContainer = ({ user, children, survey, markets, departments }) => {
    const [on, setOn] = useState('average');
    const [ready, setReady] = useState(false);
    const [data, setData] = useState({
        average: {},
        roles: {},
        markets: {},
        departments: {}
    });

    useEffect(() => {
        if (user && user.__raw) {
            setReady(true);
        } else {
            setReady(false);
        }
    }, [user]);

    useEffect(() => {
        getData(on);
    }, [survey, markets, departments]);

    const getData = async on => {
        if (ready) {
            let newData = { ...data };
            let temp;
            if (on !== 'average') {
                temp = await getCarbonPrice(user.__raw, survey, 'average', Object.values(markets), Object.values(departments));
                newData = { ...newData, 'average': { carbonData: temp.carbonData, price: temp.price } };
            }
            temp = await getCarbonPrice(user.__raw, survey, on, Object.values(markets), Object.values(departments));
            newData = { ...newData, [on]: { carbonData: temp.carbonData, price: temp.price } };
            setData(newData);
            setOn(on);
        }
    };

    return cloneElement(children, { ready, data, getData })
};

export default CarbonPriceContainer;