import React, { Fragment, useEffect, useState } from "react";
import constants from "../../constants";
import colors from "../../styles/colors";
import Grid from "../gridSystem/Grid/Grid";
import GridColumn from "../gridSystem/GridColumn/GridColumn";
import Slider from "../Slider/Slider";
import InputField from "../InputField/InputField";
import Title from "../Title/Title";
import ButtonAction from "../Button/ButtonAction";
import './GoalSetting.scss';

const GoalSetting = ({ values, currentGoal: propsGoal, addGoal, onSave, onChange, resetGoal }) => {
    const [currentGoal, setCurrentGoal] = useState(null);
    const [currentOveriewGoal, setCurrentOverviewGoal] = useState(null);
    const [startValue, setStartValue] = useState({});
    const map = Object.entries(constants.l1).reduce((prev, [key, value]) => ({ ...prev, ...(Object.keys(value)).reduce((p, c) => ({ ...p, [c]: key }), {}) }), {});

    useEffect(() => {
        setCurrentGoal(propsGoal);
        setStartValue(propsGoal);
        setCurrentOverviewGoal(Object.entries(constants.l1).reduce((prev, [key, value]) => ({ ...prev, [key]: Math.round(Object.keys(value).reduce((p, c) => p + propsGoal[c], 0) / 2) }), {}));
    }, [propsGoal]);

    useEffect(() => {
        if (resetGoal) {
            setCurrentGoal(propsGoal);
            setStartValue(propsGoal);
            setCurrentOverviewGoal(Object.entries(constants.l1).reduce((prev, [key, value]) => ({ ...prev, [key]: Math.round(Object.keys(value).reduce((p, c) => p + propsGoal[c], 0) / 2) }), {}));
        }
    }, [resetGoal, propsGoal])

    const handleOnChange = (key, value) => {
        let newGoal = { ...currentGoal };
        newGoal = { ...newGoal, [key]: value };
        let newCurrentOveriewGoal = { ...currentOveriewGoal };
        newCurrentOveriewGoal = { ...newCurrentOveriewGoal, [map[key]]: Math.round(Object.keys(constants.l1[map[key]]).reduce((prev, curr) => prev + (newGoal[curr] || 0), 0) / 2) }
        setCurrentGoal(newGoal);
        setCurrentOverviewGoal(newCurrentOveriewGoal);
        onChange(propsGoal, currentGoal);
    }

    const handleOnChangeInput = (key, value) => {
        const newStartValue = { ...startValue };
        let newValue = parseInt(value);
        if (newValue < 0) newValue = 0;
        if (newValue > 100) newValue = 100;
        setStartValue({ ...newStartValue, [key]: newValue || 0 });
        handleOnChange(key, newValue);
    }

    const handleOnSave = () => {
        addGoal(currentGoal);
        onSave(currentGoal);
    }

    return (
        <>
            <div>
                {Object.entries(constants.l0).map(([keyL0, l0]) => (
                    <div key={keyL0} className="GoalSetting__section">
                        <div>
                            <Grid>
                                <GridColumn size={9} mobileSize={9} noPadding={true}>
                                    <div className="GoalSetting__title">
                                        <Title title={l0.name} color={colors.color__black} variation={l0.color} fontSize="fs-m" />
                                    </div>
                                </GridColumn>
                                <GridColumn size={3} mobileSize={3} noPadding={true}>
                                    <InputField align="center" readOnly={true} backgroundColor="white" color={colors.color__gray1} value={(currentOveriewGoal && currentOveriewGoal[keyL0]) || ''} />
                                </GridColumn>
                            </Grid>
                            <div className="GoalSetting__line"></div>
                        </div>
                        <div className="GoalSetting__relative">
                            <Grid gridRowGap="0">
                                {Object.entries(constants.l1[keyL0]).map(([key, l1], index) => (
                                    <Fragment key={key}>
                                        <GridColumn size={12} noPadding={true}>
                                            <p className="GoalSetting__headline">{l0.name[0]}.{index + 1}: {l1}</p>
                                        </GridColumn>
                                        <GridColumn size={9} mobileSize={9} noPadding={true}>
                                            <Slider color={l0.color} startValue={startValue[key]} currentValue={values && values[`${keyL0}-${key}`]} onChange={value => handleOnChange(key, value)} reset={resetGoal} />
                                        </GridColumn>
                                        <GridColumn size={3} mobileSize={3} noPadding={true}>
                                            <div className="GoalSetting__center">
                                                <InputField align="center" fontWeight="bold" type="number" min={0} max={100} backgroundColor="white" value={currentGoal ? currentGoal[key] : ''} onChange={value => handleOnChangeInput(key, value)} />
                                            </div>
                                        </GridColumn>
                                    </Fragment>
                                ))}
                            </Grid>
                        </div>
                    </div>
                ))}
            </div>
            <div className="GoalSetting__section GoalSetting__margin">
                <ButtonAction type="save" fullWidth={true} onClick={handleOnSave} innerStyle={{ height: '3rem' }} />
            </div>
        </>
    )
}

export default GoalSetting;