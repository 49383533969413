import React, { useEffect, useState } from "react";
import Popup from "../Popup/Popup";
import Table from "../Table/Table";
import CheckboxField from "../CheckboxField/CheckboxField";
import { ReactComponent as Survey } from '../../assets/icons/survey.svg';
import InputField from "../InputField/InputField";
import ButtonAction from "../Button/ButtonAction";
import TextArea from "../TextArea/TextArea";
import './SurveyPopup.scss';

const SurveyPopup = ({ onCancel, survey, startMode, onSave, onSubmit }) => {
    const [editMode, setEditMode] = useState(startMode === 'edit' || false);
    const [newSurvey, setNewSurvey] = useState(null);
    const [allSelected, setAllSelected] = useState(false);

    useEffect(() => {
        setNewSurvey(survey);
        const members = survey.members;
        let allPicked = true;
        for (let i = 0; i < members.length; i++) {
            if (!members[i].selected) {
                allPicked = false;
                break;
            }
        }
        setAllSelected(allPicked);
    }, [survey]);

    const handleChanges = (key, value) => {
        setNewSurvey({
            ...newSurvey,
            [key]: value
        })
    }

    const handleMembers = index => {
        let members = [...newSurvey.members];
        let member = { ...members[index], selected: !members[index].selected };
        members = [...members.slice(0, index), member, ...members.slice(index + 1)];
        handleChanges('members', members);
        let allPicked = true;
        for (let i = 0; i < members.length; i++) {
            if (!members[i].selected) {
                allPicked = false;
                break;
            }
        }
        setAllSelected(allPicked);
    };

    const handleAllSelected = () => {
        const selected = !allSelected;
        setAllSelected(selected);
        const newMembers = [...newSurvey.members];
        handleChanges('members', newMembers.map(member => ({ ...member, selected: selected })));
    };

    return newSurvey && (
        <Popup
            color="white"
            onCancel={onCancel}
            onSubmit={() => onSubmit(newSurvey)}
            cancelType="close"
            submitType="send"
            submitText="survey"
            submitDisabled={editMode}
            fields={[
                {
                    type: 'component', name: 'Survey name', value: (
                        <div className="SurveyPopup__tableHeader">
                            <div>
                                <Survey height={32} width={32} />
                                <InputField fontSize="fs-l" fontWeight="bold" backgroundColor="white" name="name" readOnly={!editMode} value={newSurvey.name} placeholder={editMode ? 'Survey name' : 'Undefined'} onChange={value => handleChanges('name', value)} />
                            </div>
                            {!editMode && <ButtonAction type="edit" onClick={() => setEditMode(true)} />}
                            {editMode && <ButtonAction type="save" onClick={() => setEditMode(false)} />}
                        </div>
                    )
                },
                { type: 'component', name: 'Introduction', value: <TextArea color="white" value={newSurvey.introduction} name="introduction" readOnly={!editMode} placeholder={editMode ? 'Introduction' : 'No intro set'} onChange={value => handleChanges('introduction', value)} /> },
                {
                    type: 'component', value: (
                        <Table
                            color="white"
                            headers={[{ name: <div className="SurveyPopup__tableHeader"><CheckboxField checked={allSelected} onChange={handleAllSelected} />Name</div> }, { name: 'Sent' }, { name: 'Status' }]}
                            data={newSurvey.members.map((member, index) => ({
                                id: member.id,
                                columns: [
                                    { id: 'selected', type: 'checkbox', label: `${member.firstname} ${member.lastname}`, value: member.selected, onChange: () => handleMembers(index, member) },
                                    { id: 'sent', type: 'text', label: member.sentAt && <span title={member.sentAt}>{member.sentAt.split('T')[0]}</span> },
                                    { id: 'status', type: 'text', label: member.status },
                                ]
                            }))}
                        />
                    )
                }
            ]}
            extraActionButtons={[{ type: 'save', text: 'Save draft', action: () => onSave(newSurvey) }]}
        />
    )
}

export default SurveyPopup;