import React, { Fragment, useEffect, useState } from "react";
import constants from "../../../constants";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import BarList from "../../BarList/BarList";
import Grid from "../../gridSystem/Grid/Grid";
import Block from "../../Block/Block";
import { divideMainAndSecondary } from "../../../helpers";
import { ReactComponent as Person } from '../../../assets/icons/person.svg';
import Alignment from "../../Alignment/Alignment";

const OverviewL1 = ({ alignment, data, goal, loading, getDataOnFilter, markets, departments }) => {
    const [onAlignment, setOnAlignment] = useState({ key: null, name: 'Show average', value: 'average' });
    const [map, setMap] = useState({ markets: {}, departments: {}, roles: constants.roles });
    useEffect(() => {
        const newMap = alignment || {
            ...map,
            roles: constants.roles
        };
        setMap(newMap);
        setOnAlignment({
            ...onAlignment,
            values: newMap[onAlignment.key]
        });
    }, [alignment]);

    return (
        <Block
            color="white"
            title="Success Factors"
        >
            <Grid>
                <GridColumn size={12} noPadding={true}>
                    <Alignment
                        onClick={e => {
                            getDataOnFilter(e.value);
                            setOnAlignment({ key: e.value, values: map[e.value] || null, name: e.name });
                        }}
                        icon={(onAlignment && onAlignment.key === 'roles') ? props => <Person {...props} /> : null}
                        legends={onAlignment && onAlignment.values}
                        isSelected={onAlignment && onAlignment.name}
                    />
                </GridColumn>
                {(Object.values(constants.l0).map(level => (
                    <Fragment key={level.name + 'titleBarList'}>
                        <GridColumn size={12} displayOnly="mobile">
                            <h2 className={`Overview__title Overview__title--${level.color} Overview__title--mobile`}>{level.name}</h2>
                        </GridColumn>
                        {(!loading && data) && Object.values(data.children[level.id].children).map((child, index) => (
                            <GridColumn key={child.id + 'barlist'} size={4} desktopOrder={index + 1} noPadding={true}>
                                <div className="Overview__barList">
                                    <h3 className="Overview__title--small">{child.description}</h3>
                                    <BarList
                                        data={divideMainAndSecondary(child, level, onAlignment)}
                                        color={level.color} prefix={level.name[0] + (index + 1) + '.'} goal={goal && goal[child.label]}
                                    />
                                </div>
                            </GridColumn>
                        ))}
                    </Fragment>
                )))}
            </Grid>
        </Block >
    );
}

export default OverviewL1;