import React from "react";
import logo from '../../assets/img/anothertomorrow_logo_black.png';
import { Box, Flex, Image, Link } from "@chakra-ui/react";

const Footer = () => (
    <Flex w="100%" bg="white" justifyContent="center" p={10}>
        <Box h={5}>
            <Link href="https://www.anothertomorrow.com/">
                <Image src={logo} h="100%" />
            </Link>
        </Box>
    </Flex>
)

export default Footer;