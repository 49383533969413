import React from "react";
import './SettingsActions.scss';

const SettingsActions = ({ children, align }) => {
    return (
        <div className="SettingsActions" style={{ justifyContent: align }}>
            {children}
        </div>
    )
}

export default SettingsActions;