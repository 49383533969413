import React from 'react';
import { useSearchParams } from 'react-router-dom';
import LoginButton from '../LoginButton/LoginButton';
import './LoginPage.scss';

const LoginPage = () => {
    const [searchParams] = useSearchParams();
    const typeformId = searchParams.get('typeformid');
    const company = searchParams.get('company');

    return (
        <div className='LoginPage'>
            <h1 className='LoginPage__title'>Welcome</h1>
            <div className='LoginPage__button'>
                <LoginButton typeformId={typeformId} company={company || ''} />
            </div>
        </div>
    )
}

export default LoginPage;