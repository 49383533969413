import { Box, Flex, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Link as ReactRouterLink, useLocation } from "react-router-dom";

const OverviewTabs = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const page = queryParams.get('page');

    const createLinkWithPage = newPage => {
        queryParams.set('page', newPage);
        return `${location.pathname}?${queryParams.toString()}`;
    };

    const Tab = ({ text, active, to }) => (
        <Link to={to} as={ReactRouterLink} _hover={{ color: 'primary', fontWeight: 'bold' }} p={2}>
            <Text fontWeight={active && "bold"}>{text}</Text>
        </Link>
    );

    return (
        <Box bg="gray.400">
            <Flex px={12} maxW={1400} gap={5}>
                <Tab to={createLinkWithPage('overview')} text="Scores" active={page === 'overview'} />
                <Tab to={createLinkWithPage('analysis')} text="Graphs" active={page === 'analysis'} />
            </Flex>
        </Box>
    );
};

export default OverviewTabs;