import React from "react";
import Dropdown from "../../Dropdown/Dropdown";
import Toggle from "../../Toggle/Toggle";
import './UsersListFilters.scss';
import { Flex } from "@chakra-ui/react";

const UsersListFilters = ({ filters, handleFilter }) => (
    <div className="UsersListFilters">
        <Flex className="UsersListFilters__container" h="50px">
            <Dropdown color="gray-5" options={Object.values(filters.role)} selected={Object.values(filters.role).filter(role => role.selected)} multipleSelection={true} label="Role" fontSize="fs-s" onChange={value => handleFilter('role', value)} onClick={() => { }} />
            <Dropdown color="gray-5" options={Object.values(filters.market)} multipleSelection={true} selected={Object.values(filters.market).filter(market => market.selected)} label="Market" fontSize="fs-s" onChange={value => handleFilter('market', value)} onClick={() => { }} />
            {Object.values(filters.department).length > 0 && <Dropdown color="gray-5" options={Object.values(filters.department)} multipleSelection={true} selected={Object.values(filters.department).filter(department => department.selected)} label="Department" fontSize="fs-s" onChange={value => handleFilter('department', value)} onClick={() => { }} />}
            <Toggle textOn="Only incomplete" onClick={value => handleFilter('incomplete', value)} />
        </Flex>
    </div>
);

export default UsersListFilters;