import colors from "./styles/colors";

const namespace = 'https://transitionview.anothertomorrow.com/';
const constants = {
    MAX_SCORE: 100,
    REF_SCORE: 45,
    l0: {
        willingness: {
            id: "willingness",
            name: 'Willingness',
            color: 'yellow',
            colorCode: colors.color__yellow,
            description: 'Lorem ipsum dolor sit amet. Est impedit velit est facilis consequatur ex quam vitae in galisum cupiditate id dolores omnis. Ex voluptas dolorem ut debitis molestias et error dolor sit galisum voluptas.'
        },
        readiness: {
            id: "readiness",
            name: 'Readiness',
            color: 'blue',
            colorCode: colors.color__blue,
            description: 'Lorem ipsum dolor sit amet. Est impedit velit est facilis consequatur ex quam vitae in galisum cupiditate id dolores omnis. Ex voluptas dolorem ut debitis molestias et error dolor sit galisum voluptas.'
        },
        openness: {
            id: "openness",
            name: 'Trustworthiness',
            color: 'pink',
            colorCode: colors.color__pink,
            description: 'Lorem ipsum dolor sit amet. Est impedit velit est facilis consequatur ex quam vitae in galisum cupiditate id dolores omnis. Ex voluptas dolorem ut debitis molestias et error dolor sit galisum voluptas.'
        }
    },
    l1: {
        willingness: {
            ignite: 'Insights, awareness & attitudes',
            commit: 'Leadership & commitment'
        },
        readiness: {
            act: 'Activities, action & incentives',
            innovate: 'Innovation'
        },
        openness: {
            measure: 'Measurement & tracking',
            leverage: 'Transparency & reliability'
        }
    },
    willingness: {
        ref: 0.5,
    },
    readiness: {
        ref: 0.80,
    },
    disclosure: {
        ref: 0.65,
    },
    namespace: {
        role: namespace + 'role'
    },
    roles: {
        'executive': { id: 'executive', sort: 1, name: 'Executive management', colorCode: colors.color__turquoise, color: 'turquoise' },
        'senior': { id: 'senior', sort: 2, name: 'Senior management', colorCode: colors.color__purple, color: 'purple' },
        'operational': { id: 'operational', sort: 3, name: 'Operational staff', colorCode: colors["color__blue-light"], color: 'blue-light' }
    },
    permissions: {
        admin: { name: 'Admin' },
        superUser: { name: 'Survey user' }
    },
    sample: {
        survey: {
            title: "<b>Welcome</b>"
        }
    },
    status: {
        DRAFT: "DRAFT",
        SENT: "SENT",
        CHANGED: "CHANGED",
        PENDING: "PENDING"
    }
}

export const dynamicConstants = value => ({
    sample: {
        survey: {
            description: `The purpose of this survey is to map out ${value}'s current capabilities to effectively drive a green transition. The questions highlight key areas for overcoming inaction and accelerating climate action. Please answer to the best of your ability – we wish to get a snapshot of the organisation, not evaluate any particular individual.<br><br>TransitionView is developed by Another Tomorrow together with climate scientists Sverker Jagers and Åsa Löfgren.`
        }
    }
})

export default constants;