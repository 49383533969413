import { cloneElement, useEffect, useState } from "react";
import { getOffsettingProgram } from "../api";
import colors from "../styles/colors";

const TransitionImpactContainer = ({ user, survey, children, markets, departments }) => {
    const [error, setError] = useState(false);
    const [on, setOn] = useState('average');
    const [data, setData] = useState({
        average: [],
        roles: [],
        markets: [],
        departments: []
    });
    const [ready, setReady] = useState(false);
    const choices = {
        yes: { label: 'Yes', colorCode: colors["color__green-light"], color: 'green-light' },
        no: { label: 'No', colorCode: colors.color__red, color: "red" },
        dk: { label: "I don't know", colorCode: colors.color__orange, color: 'orange' }
    };

    useEffect(() => {
        if (user && user.__raw) {
            setReady(true);
            getData(on);
        } else {
            setReady(false);
        }
    }, [user, markets, departments]);

    const getData = async on => {
        if (ready) {
            let newData = { ...data };
            let netZero;
            if (on !== 'average') {
                netZero = await getOffsettingProgram(user.__raw, survey, on, Object.values(markets), Object.values(departments));
                newData = { ...newData, [on]: netZero.data  };
                setOn(on);
            } else {
                netZero = await getOffsettingProgram(user.__raw, survey, 'average', Object.values(markets), Object.values(departments));
                newData = { ...newData, 'average': netZero.data };
                setOn('average');
            }
            setData(newData);
        }
    }

    const findChoice = (key) => data['average'].find(d => d.choice === key);

    return cloneElement(children, { error, data, getData, ready, choices, findChoice, showOn: 'choice', title: 'Are we reliant on offsetting programmes?' });
};

export default TransitionImpactContainer;