import React, { useEffect, useState } from "react";
import Block from "../../Block/Block";
import Alignment from "../../Alignment/Alignment";
import { ReactComponent as Person } from '../../../assets/icons/person.svg';
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import BarChart from "../../BarChart/BarChart";
import colors from "../../../styles/colors";

const TimeScaleNetZero = ({ title, ready, data, getData, alignment, choices, findChoice, showOn }) => {
    const [on, setOn] = useState({ name: 'Show average', value: 'average' });

    useEffect(() => {
        if (ready) getData('average');
    }, [ready]);

    const handleOnChangeAlignment = target => {
        setOn({ ...target, value: target.value || 'average' });
        getData(target.value || 'average');
    };

    return ready && (
        <Block
            color="white"
            title={title}
        >
            <Alignment
                isSelected={on.name}
                onClick={handleOnChangeAlignment}
                legends={on.value !== 'average' && alignment[on.value]}
                icon={on.value === 'roles' && (props => <Person {...props} />)}
            />
            <Grid>
                <GridColumn size={12} noPadding>
                    {(
                        <BarChart
                            isStacked={true}
                            compressed={true}
                            prefix="%"
                            numberOnTop={true}
                            data={data[on.value]?.length > 0 ? data[on.value] && Object.entries(choices).map(([key, value]) => {
                                const choice = findChoice(key);
                                if (!choice) return {
                                    id: key,
                                    label: value.label,
                                    score: 0,
                                    data: []
                                };
                                return {
                                    label: value.label,
                                    data: [{
                                        ...value,
                                        colorCode: !!on.value && on.value !== 'average' ? undefined : value.colorCode,
                                        score: choice.percentage,
                                        data: !!on.value && on.value !== 'average' ? data[on.value]
                                            ?.filter(item => item[showOn] === choice[showOn] && alignment[on.value][item.id.toLowerCase()])
                                            .map(item => ({
                                                id: item.id,
                                                score: item.percentage,
                                                colorCode: colors[`color__${alignment[on.value][item.id.toLowerCase()]?.color}`],
                                                color: alignment[on.value][item.id.toLowerCase()].color,
                                                label: alignment[on.value][item.id.toLowerCase()].name,
                                            })) : []
                                    }]
                                };
                            }) : Object.values(choices).map(value => ({ ...value, score: 0, data: [] }))}
                        />
                    )}
                </GridColumn>
            </Grid>
        </Block>
    )
};

export default TimeScaleNetZero;
