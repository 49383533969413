import React, { useRef, useState } from "react";
import useOutsideClick from "../../../hooks/useOutsideClick";
import "./Description.scss";

const Description = ({ description }) => {
    const ref = useRef();
    const [showDesc, setShowDesc] = useState(false);

    useOutsideClick(ref, () => {
        if (showDesc) {
            setShowDesc(false);
        }
    });

    return (
        <div
            ref={ref}
            className="Description"
            onClick={() => setShowDesc(!showDesc)}
        >
            ?
            {showDesc && (
                <div className="Description__popup">
                    <p>{description}</p>
                </div>
            )}
        </div>
    );
};

export default Description;