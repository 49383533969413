import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { addSurvey, createForm, deleteSurvey, getCompanySurveys, sendSurvey, updateSurvey } from "../../../api";
import ButtonAction from "../../Button/ButtonAction";
import Popup from "../../Popup/Popup";
import Table from "../../Table/Table";
import SettingsActions from "./SettingsActions";
import { sortedRoles } from "../../../helpers";
import constants from "../../../constants";
import SurveyPopup from "../../SurveyPopup/SurveyPopup";
import Loader from "../../Loader/Loader";
import './SettingsSurveys.scss';

const SettingsSurveys = ({ user, company }) => {
    const navigate = useNavigate();
    const [surveys, setSurveys] = useState([]);
    const [selectedForm, setSelectedForm] = useState(null);
    const [successCopy, setSuccessCopy] = useState(false)
    const [editSurvey, setEditSurvey] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    useEffect(() => {
        if (user && user.__raw && company.id) {
            setLoading(true);
            getCompanySurveys(user.__raw, company.id).then(res => {
                if (res.status !== 200) {
                    setError(true)
                } else {
                    setSurveys(res.data)
                }
                setLoading(false);
            });
        }
    }, [user, company]);

    const createCopyText = () => `transitionview.anothertomorrow.com?company=${company.name}&form=${selectedForm && selectedForm.typeformId}&tag=ns102`;

    const popupProps = {
        delete: {
            title: `Are you sure you want to delete ${selectedForm && selectedForm.name}?`,
            submitText: 'Delete',
            onSubmit: () => {
                deleteSurvey(user.__raw, selectedForm.id)
                    .then(res => {
                        if (res.status === 200) {
                            const index = surveys.findIndex(survey => survey.id === selectedForm.id);
                            setSurveys([...surveys.slice(0, index), ...surveys.slice(index + 1)]);
                            setSelectedForm(null);
                        }
                    })
            }
        },
        copy: {
            title: `url: ${createCopyText()}`,
            submitText: 'Copy',
            onSubmit: () => {
                setSuccessCopy(true);
                setTimeout(() => {
                    setSuccessCopy(false);
                }, 2500);
                navigator.clipboard.writeText(createCopyText());
            },
            successMessage: successCopy && 'Copied!'
        }
    }

    const addNewSurvey = (survey, callback) => {
        createForm(user.__raw, { title: survey.name }).then(res => {
            if (res.status === 200) {
                addSurvey(user.__raw, { ...survey, companyId: company.id, typeformId: res.data.id })
                    .then(response => {
                        const { id } = response.data;
                        handleUpdateSurvey({ ...survey, id });
                        callback && callback();
                    });
            }
        })
    }

    const handleUpdateSurvey = (survey, callback) => {
        updateSurvey(user.__raw, { ...survey, users: survey.members.filter(member => member.selected) || [] })
            .then(res => {
                if (res.status === 200) {
                    setEditSurvey(null);
                    if (survey.type === 'new') {
                        setSurveys([...surveys, res.data]);
                    } else {
                        const index = surveys.findIndex(s => s.id === survey.id);
                        setSurveys([...surveys.slice(0, index), res.data, ...surveys.slice(index + 1)])
                    }
                    callback && callback();
                }
            });
    }

    const handleOnSave = (survey, callback) => {
        if (editSurvey.type === 'new') {
            addNewSurvey(survey, callback);
        } else {
            handleUpdateSurvey(survey, callback);
        }
    }

    const handleOnSendSurvey = survey => {
        handleOnSave(survey, () => {
            sendSurvey(user.__raw, survey.id).then(res => {
                const index = surveys.findIndex(s => s.id === survey.id);
                setSurveys([...surveys.slice(0, index), res.data, ...surveys.slice(index + 1)]);
            })
        });
    }

    return (
        <>
            {editSurvey && (
                <SurveyPopup
                    onCancel={() => setEditSurvey(null)}
                    survey={editSurvey}
                    startMode={editSurvey.type === 'new' ? 'edit' : 'saved'}
                    onSave={handleOnSave}
                    onSubmit={handleOnSendSurvey}
                />
            )}
            {(user && user[constants.namespace.role] === 'admin') && (
                <div className="SettingsSurveys__add">
                    <div>
                        <ButtonAction type="primary" text="Create Survey" onClick={() => navigate(`/company/${company.id}/create/survey/info`)} />
                    </div>
                </div>
            )}
            {loading && (
                <div className="SettingsSurveys__loader">
                    <Loader />
                </div>
            )}
            <Table
                color="white"
                headers={[{ name: 'Survey', width: '' }, { name: 'Status', width: '' }, { name: 'Total recipients', width: '' }, ...sortedRoles.map(role => ({ name: role.name, width: '' })), { name: '', width: '0px' }]}
                data={surveys.map(survey => ({
                    id: survey.id,
                    columns: [
                        { id: 'name', type: 'link', target: '_self', fontWeight: 'bold', label: survey.name, url: `/company/${company.id}/dashboard`, search: `?page=overview&survey=${survey.id}` },
                        { id: 'status', type: 'text', label: survey.status },
                        { id: 'total', type: 'number', align: 'center', label: `${survey.count_recipients_submitted}/${survey.count_recipients}` },
                        ...sortedRoles.map(role => ({ id: role, type: 'number', align: 'center', label: survey._count ? `${survey._count[`${role.id.toUpperCase()}_submitted`]}/${survey._count[role.id.toUpperCase()]}` : '' })),
                        {
                            id: 4, type: 'text', align: 'right', label: (
                                <SettingsActions align="flex-end">
                                    <>
                                        {survey.editable && <Link to={`/company/${company.id}/update/survey/${survey.id}/info`}><ButtonAction type="edit" /></Link>}
                                        {survey.published_at && <ButtonAction onClick={() => setSelectedForm({ ...survey, status: 'copy' })} type="share" />}
                                        <ButtonAction onClick={() => setSelectedForm({ ...survey, status: 'delete' })} type="delete--small" />
                                    </>
                                </SettingsActions>
                            )
                        }
                    ]
                }))}
            />
            {(selectedForm && (selectedForm.status === 'copy' || selectedForm.status === 'delete')) && (
                <Popup
                    color="white"
                    {...popupProps[selectedForm.status]}
                    onCancel={() => {
                        setSelectedForm(null);
                        setSuccessCopy(false);
                    }}
                />
            )}
        </>
    )
}

export default SettingsSurveys;