import React, { Fragment, useEffect, useState } from "react";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import Preview from "../../models/Preview/Preview";
import constants from "../../../constants";
import UserChip from "../../UserChip/UserChip";
import Accordion from "../../Accordion/Accordion";
import { sortItems } from "../../../helpers";
import "./CreateSurveySummary.scss";

const CreateSurveySummary = ({ users, alias, name, title, intro, hasDepartment }) => {
    const initialRoles = {
        [constants.roles.executive.id]: [],
        [constants.roles.senior.id]: [],
        [constants.roles.operational.id]: []
    }
    const [roles, setRoles] = useState(initialRoles);

    useEffect(() => {
        let newRoles = { ...initialRoles };
        for (let i = 0; i < users.length; i++) {
            const user = users[i];
            newRoles[user.role.id] = [...newRoles[user.role.id], user];
        }
        setRoles(newRoles);
    }, [users]);

    const usersElement = userArray => (
        <div className="CreateSurveySummary__user">
            <Grid gridRowGap="1rem">
                {userArray.sort((userA, userB) => sortItems('text', 'desc', `${userA.firstname} ${userA.lastname}`, `${userB.firstname} ${userB.lastname}`)).map(user => (
                    <Fragment key={user.id}>
                        <GridColumn size={hasDepartment ? 6 : 9} noPadding={true}>
                            <UserChip user={{ ...user, name: `${user.firstname} ${user.lastname}` }} />
                        </GridColumn>
                        <GridColumn size={3} noPadding={true}>
                            {user.market.name !== "NO MARKET" && <p className="CreateSurveySummary__userColumn">{user.market.name}</p>}
                        </GridColumn>
                        {hasDepartment && <GridColumn size={3} noPadding={true}>
                            {user.department && user.department.name !== "NO DEPARTMENT" && <p className="CreateSurveySummary__userColumn">{user.department.name}</p>}
                        </GridColumn>}
                    </Fragment>
                ))}
            </Grid>
        </div>
    )

    return (
        <Grid gridColumnGap="2rem">
            <GridColumn size={6} noPadding={true}>
                <Grid gridRowGap="2rem">
                    <GridColumn size={12} noPadding={true}>
                        <p className="CreateSurveySummary__title">Survey Name</p>
                        <h2 className="CreateSurveySummary__text">{name}</h2>
                    </GridColumn>
                    <GridColumn size={12} noPadding={true}>
                        <Preview title={title} intro={intro} />
                    </GridColumn>
                </Grid>
            </GridColumn>
            <GridColumn size={6} noPadding={true}>
                <Grid>
                    <GridColumn size={12} noPadding={true}><p className="CreateSurveySummary__title">Recipients</p></GridColumn>
                    <GridColumn size={12} noPadding={true}>
                        <Accordion disabled={roles[constants.roles.executive.id].length === 0} title={<div className="CreateSurveySummary__text CreateSurveySummary__spaceBetween">{alias[constants.roles.executive.id].alias || alias[constants.roles.executive.id].name}  <span>({roles[constants.roles.executive.id].length})</span></div>}>
                            {usersElement(roles[constants.roles.executive.id])}
                        </Accordion>
                    </GridColumn>
                    <GridColumn size={12} noPadding={true}>
                        <Accordion disabled={roles[constants.roles.senior.id].length === 0} title={<div className="CreateSurveySummary__text CreateSurveySummary__spaceBetween">{alias[constants.roles.senior.id].alias || alias[constants.roles.senior.id].name}  <span>({roles[constants.roles.senior.id].length})</span></div>}>
                            {usersElement(roles[constants.roles.senior.id])}
                        </Accordion>
                    </GridColumn>
                    <GridColumn size={12} noPadding={true}>
                        <Accordion disabled={roles[constants.roles.operational.id].length === 0} title={<div className="CreateSurveySummary__text CreateSurveySummary__spaceBetween">{alias[constants.roles.operational.id].alias || alias[constants.roles.operational.id].name} <span>({roles[constants.roles.operational.id].length})</span></div>}>
                            {usersElement(roles[constants.roles.operational.id])}
                        </Accordion>
                    </GridColumn>
                    <GridColumn size={12} noPadding={true}><div className="CreateSurveySummary__text CreateSurveySummary__spaceBetween">Total <span>{users.length}</span></div></GridColumn>
                </Grid>
            </GridColumn>
        </Grid>
    )
}

export default CreateSurveySummary;