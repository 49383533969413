import React from "react";
import ButtonAction from "../Button/ButtonAction";
import Toggle from "../Toggle/Toggle";
import "./Menubar.scss";

const Menubar = ({ items }) => {
    const getElement = (item, key) => {
        switch (item.type) {
            case 'element':
                return item.element;
            case 'toggle':
                return <Toggle key={key} textOff={item.textOff} textOn={item.textOn} onClick={item.onClick} isChecked={item.checked} />
            case 'button':
            default:
                return <ButtonAction key={key} type="primary" onClick={item.onClick} text={item.text} />
        }
    }
    return (
        <div className="Menubar">
            {items.map((item, index) => getElement(item, index))}
        </div>
    )
}

export default Menubar;