import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Box, Button, Divider, Flex, Heading, Link, Menu, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { Link as ReactRouterLink, useParams } from "react-router-dom";
import { ReactComponent as ChartIcon } from "../../../assets/icons/chart.svg";
import { ReactComponent as SurveyIcon } from "../../../assets/icons/survey.svg";
import { ReactComponent as BuildingIcon } from "../../../assets/icons/building.svg";
import { ReactComponent as Logo } from "../../../assets/icons/logo.svg";
import constants from "../../../constants";

const SidebarLeft = ({ user, active }) => {
    const { company } = useParams();
    const { logout } = useAuth0();
    const [isOpen, setIsOpen] = useState(false);

    const SidebarItem = ({ to, title, Icon, isActive }) => (
        <Link
            w="full"
            as={ReactRouterLink}
            to={to}
            onClick={() => setIsOpen(false)}
            _hover={{
                textDecoration: "none",
                color: "white",
                p: {
                    color: "white"
                }
            }}
        >
            <Flex
                w="100%"
                p={4}
                title={title}
                gap={4}
                bg={isActive && "primary"}
                _hover={{
                    bg: "primary",
                    cursor: "pointer",
                    svg: {
                        fill: "white"
                    }
                }}
            >
                <Icon fill={isActive && "white"} />
                {isOpen && <Text color={isActive && "white"}>{title}</Text>}
            </Flex>
        </Link>
    );

    return (
        <Flex
            zIndex={99}
            pos="fixed"
            bg="white"
            w={{ base: "full", md: isOpen ? 72 : 14 }}
            h="100vh"
            flexDirection="column"
            justifyContent="space-between"
            boxShadow="lg"
        >
            <Button
                zIndex={999}
                pos="absolute"
                top={16}
                right={-5}
                borderRadius={12}
                onClick={() => setIsOpen(!isOpen)}
                display="flex"
                gap={1}
                h={5}
                w={3}
                boxShadow="sm"
                bg="gray.100"
                _hover={{ bg: "primary", div: { filter: "invert(1)" } }}
            >
                <Box
                    w={0}
                    h={0}
                    borderTop="6px solid transparent"
                    borderBottom="6px solid transparent"
                    borderRight="6px solid black"
                />
                <Box
                    w={0}
                    h={0}
                    borderTop="6px solid transparent"
                    borderBottom="6px solid transparent"
                    borderLeft="6px solid black"
                />
            </Button>
            <Box>
                <Flex alignItems="center" mb={3}>
                    <Box my={5} mx={2} h={8}>
                        <Logo width="40px" height="32px" />
                    </Box>
                    {isOpen && <Heading fontSize="xl" textTransform="none">TransitionView</Heading>}
                </Flex>
                {company && <SidebarItem isActive={active === 'dashboard'} title="Dashboard" to={`/company/${company}/dashboard?page=overview`} Icon={props => <ChartIcon fill="black" {...props} />} />}
                {company && <SidebarItem isActive={active === 'admin'} title="Handle surveys" to={`/company/${company}/admin`} Icon={props => <SurveyIcon fill="black" {...props} />} />}
                {company && <Divider my={3} />}
                {(user && user[constants.namespace.role] === "admin") && <SidebarItem isActive={active === 'companies'} title="Companies" to={`/`} Icon={props => <BuildingIcon fill="black" {...props} />} />}
            </Box>
            <Flex py={3}>
                <Menu>
                    <MenuButton borderRadius="4px" w="100%" px={1} py={2} _hover={{ bg: "gray.50" }}>
                        <Flex gap={2} textAlign="left">
                            <Avatar name={user?.nickname} src={user?.picture} />
                            {isOpen && <Box>
                                <Text fontWeight={700}>{user?.nickname}</Text>
                                <Text fontSize="sm">{user?.email}</Text>
                            </Box>}
                        </Flex>
                    </MenuButton>
                    <MenuList ml={3}>
                        <MenuGroup title="ACCOUNT">
                            <Flex p={2} gap={2}>
                                <Avatar size="sm" name={user?.nickname} src={user?.picture} />
                                <Box>
                                    <Text fontWeight={700}>{user?.nickname}</Text>
                                    <Text fontSize="sm">{user?.email}</Text>
                                </Box>
                            </Flex>
                        </MenuGroup>
                        <MenuDivider />
                        <MenuGroup>
                            <MenuItem as="a" cursor="pointer" _hover={{ color: 'primary' }} onClick={() => logout({ returnTo: window.location.origin })}>Log out</MenuItem>
                        </MenuGroup>
                    </MenuList>
                </Menu>
            </Flex>
        </Flex>
    );
};

export default SidebarLeft;