export const fontSizes =  {
    fsxxxl: 'calc(1rem / 16 * 68)',
    fsxxl: 'calc(1rem / 16 * 42)',
    fsxl: 'calc(1rem / 16 * 28)',
    fsl: 'calc(1rem / 16 * 20)',
    fsm: 'calc(1rem / 16 * 16)',
    fss: 'calc(1rem / 16 * 14)'
};

export const fontWeights =  {
    fwLight: 300,
    fwRegular: 400,
    fwMedium: 500,
    fwBold: 700
};