import React, { useEffect, useState } from "react";
import { getCompanyMembers } from "../../../api";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import Hero from "../../Hero/Hero";
import Tabs from "../../Tabs/Tabs";
import SettingsSurveys from "./SettingsSurveys";
import { ReactComponent as Survey } from '../../../assets/icons/survey.svg';
import Alert from "../../Alert/Alert";
import './Settings.scss';

const Settings = ({ user, company, error: errorParams }) => {
    const [active, setActive] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        setActive(0);
    }, [user]);

    useEffect(() => {
        setError(errorParams);
    }, [errorParams]);

    if(error && !user){
        return <Alert />;
    }

    return user && (
        <div>
            {error && <Alert />}
            <Hero
                name={company && company.name}
                score={null}
                hideScore={true}
                details={null}
            />
            <div className="Settings__wrapper">
                <Grid>
                    <GridColumn size={12}>
                        <Tabs tabs={[{ name: <><Survey />Surveys</>, active: active === 0 }]} handleOnClick={index => setActive(index)} />
                    </GridColumn>
                    <GridColumn size={12}>
                        <div className={`${active !== 0 ? 'Settings--hidden' : ''}`}>
                            <SettingsSurveys user={user} company={company} />
                        </div>
                    </GridColumn>
                </Grid>
            </div>
        </div>
    )
}

export default Settings;