import React, { Fragment } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as Checkmark } from '../../assets/icons/checkmark.svg';
import './Steps.scss';

const Steps = ({ type, steps, fontStyle }) => {
    let navigate = useNavigate();
    let location = useLocation();

    return (
        <div className="Steps">
            {steps.map((step, index) => (
                <Fragment key={step.id}>
                    <div className="Steps__step">
                        <div className={`Steps__circle--${type === 'number' ? 'number' : step.status}`} onClick={() => step.status === 'complete' && navigate(location.pathname.replace(/[^/]*$/, step.id))}>
                            {step.status === 'active' && <div className="Steps__circle--inner"></div>}
                            {(step.status === 'complete' || type === "number") && <div className="Steps__icon">{type === 'number' ? (index + 1) : <Checkmark />}</div>}
                        </div>
                        <p style={{ ...fontStyle }}>{step.title}</p>
                    </div>
                </Fragment>
            ))}
        </div>
    )
}

export default Steps;