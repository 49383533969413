import React, { useState } from "react";
import "./Accordion.scss";

const Accordion = ({ title, children, disabled }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className={`Accordion${disabled ? '--disabled' : ''} Accordion${open ? '--open' : '--closed'}`}>
            <div className="Accordion__title" onClick={() => setOpen(!open)}>{title}</div>
            {open && <div className="Accordion__child">{children}</div>}
        </div>
    )
}

export default Accordion;