import React, { useRef } from "react";
import ButtonAction from "../Button/ButtonAction";
import './TextArea.scss';

const TextArea = ({ color, rows, placeholder, onChange, value, readOnly }) => {
    const ref = useRef();

    const handleText = (e, cmd) => {
        e.preventDefault();
        document.execCommand(cmd, false, null);
    }

    const buttonStyle = {
        borderColor: "transparent",
        style: { height: '2rem', width: '2rem' },
        innerStyle: { fontSize: '14px' }
    }

    return (
        <div className="TextArea">
            <div className="TextArea__actions">
                <ButtonAction type="custom" {...buttonStyle} text="B" onClick={e => handleText(e, 'bold')} />
                <ButtonAction type="custom" {...buttonStyle} innerStyle={{ ...buttonStyle.innerStyle, fontStyle: 'italic' }} text="i" onClick={e => handleText(e, 'italic')} />
            </div>
            <div className="TextArea__container">
                <p className="TextArea__placeholder">{placeholder}</p>
                <div
                    ref={ref}
                    className={`TextArea__area${color ? `--${color}` : ''} ${readOnly ? 'TextArea__area--disabled':''}`}
                    style={{ minHeight: `${rows * 1.5}rem` }}
                    suppressContentEditableWarning={true}
                    contentEditable={!readOnly}
                    onInput={e => onChange(e.target.innerHTML)}
                    dangerouslySetInnerHTML={{ __html: value }}
                >
                </div>
            </div>
        </div>
    )
}

export default TextArea;