import React from "react";
import ButtonAction from "../Button/ButtonAction";
import './UserChip.scss';

const UserChip = ({ user, onClick }) => (
    <div className={`UserChip${onClick ? '--clickable' : ''}`} onClick={onClick && onClick}>
        <div className="UserChip__top">
            <p className="UserChip__text">{user.name}</p>
            {onClick && <div className="UserChip__edit">
                <ButtonAction type="edit" innerStyle={{ padding: 0, height: '1rem' }} />
            </div>}
        </div>
        <p className="UserChip__text--small">{user.email}</p>
    </div>
)

export default UserChip;