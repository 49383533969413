import React, { useState } from "react";
import InputField from "../../InputField/InputField";
import Dropdown from "../../Dropdown/Dropdown";
import ButtonAction from "../../Button/ButtonAction";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import Accordion from "../../Accordion/Accordion";
import './AddUsersPopupManual.scss';

const AddUsersPopupManual = ({ alias, markets, departments, onAdd }) => {
    const initUser = {
        firstname: '',
        lastname: '',
        email: '',
        role: {},
        market: {},
        department: {}
    }
    const [user, setUser] = useState(initUser);
    const [disabled, setDisabled] = useState(true);

    const onChange = (key, value) => {
        const newUser = { ...user, [key]: value };
        setUser(newUser);

        if (newUser.firstname !== '' && newUser.lastname !== '' && newUser.email !== '') {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }

    const onAddUser = () => {
        if (!disabled) {
            onAdd({ ...user, id: user.email });
            setUser(initUser);
            setDisabled(true);
        }
    }

    return (
        <Accordion title={<h4 className="AddUsersPopupManual__title">Add manually</h4>}>
            <Grid>
                <GridColumn size={6}>
                    <div className="AddUsersPopupManual__fields">
                        <InputField placeholder="First Name" backgroundColor="white" fontSize="fs-s" value={user.firstname} onChange={value => onChange('firstname', value)} />
                        <InputField placeholder="Last Name" backgroundColor="white" fontSize="fs-s" value={user.lastname} onChange={value => onChange('lastname', value)} />
                        <InputField type="email" placeholder="Email" backgroundColor="white" fontSize="fs-s" value={user.email} onChange={value => onChange('email', value)} />
                    </div>
                </GridColumn>
                <GridColumn size={6} height="100%">
                    <div className="AddUsersPopupManual__fields">
                        <Dropdown fullWidth={true} options={alias && Object.values(alias).map(a => ({ ...a, name: a.alias || a.name, default: a.name }))} color="gray-5" label="Role" selected={user.role.name} onChange={value => onChange('role', value)} />
                        <Dropdown fullWidth={true} options={Object.values(markets)} color="gray-5" label="Market" variation="dropdown" selected={user.market.name} onChange={value => {
                            onChange('market', value);
                        }} />
                        <Dropdown fullWidth={true} options={Object.values(departments)} color="gray-5" label="Department" variation="dropdown" selected={user.department.name} onChange={value => {
                            onChange('department', value);
                        }} />
                    </div>
                </GridColumn>
                <GridColumn size={12} >
                    <div className="AddUsersPopupManual__action">
                        <ButtonAction disabled={disabled} type="add" onClick={onAddUser} />
                    </div>
                </GridColumn>
            </Grid>
        </Accordion>
    );
}

export default AddUsersPopupManual;