import React from "react";
import './Button.scss';

const Button = ({ onClick, children, secondary, backgroundColor, borderColor, color, disabled }) => (
    <button
        className={`Button${secondary ? '--secondary' : ''} ${color ? `Button--color${color}` : ''} ${backgroundColor ? `Button--background${backgroundColor}` : ''} ${borderColor ? `Button--border${borderColor}` : ''}`}
        onClick={onClick}
        disabled={disabled}
    >
        {children}
    </button>
);

export default Button;