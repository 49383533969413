import React from "react";
import Grid from "../../gridSystem/Grid/Grid";
import GridColumn from "../../gridSystem/GridColumn/GridColumn";
import InputField from "../../InputField/InputField";
import TextArea from "../../TextArea/TextArea";
import Preview from "../../models/Preview/Preview";
import './CreateSurveyInfoForm.scss';
import constants from "../../../constants";

const CreateSurveyInfoForm = ({ name, title, intro, previewValue, handleOnChange, status }) => (
    <Grid gridColumnGap="2rem">
        <GridColumn size={6} noPadding={true}>
            <Grid>
                <GridColumn size={12} noPadding={true}>
                    <h4 className="CreateSurveyInfoForm__title">Survey Name</h4>
                    <InputField backgroundColor="white" placeholder="Name of survey" value={name} onChange={value => handleOnChange('name', value)} />
                    <p className="CreateSurveyInfoForm__description">This is the title that will appear in your admin view</p>
                </GridColumn>
                <GridColumn size={12} noPadding={true}>
                    <h4 className="CreateSurveyInfoForm__title">Title and description</h4>
                    <TextArea rows={1} color="white" placeholder="Title" name="info" value={title} onChange={value => handleOnChange('title', value)} readOnly={status === constants.status.SENT} />
                </GridColumn>
                <GridColumn size={12} noPadding={true}>
                    <TextArea color="white" rows={10} placeholder="Description" name="info" value={intro} onChange={value => handleOnChange('intro', value)} readOnly={status === constants.status.SENT} />
                    <p className="CreateSurveyInfoForm__description">This will be the introduction message that the survey recipients will see on the first page. Consider what is important for them to understand and what they need to know about how you will continue to work with the results. Please modify the text to suit your needs.</p>
                </GridColumn>
            </Grid>
        </GridColumn>
        <GridColumn size={6} noPadding={true} height="100%">
            <Preview {...previewValue} />
        </GridColumn>
    </Grid>
)

export default CreateSurveyInfoForm;