let colors = {
    // GRAY SCALE
    color__black: "#0f0f0f",
    color__white: "#fff",
    "color__gray-0": "#222222",
    "color__gray-1": "#333333",
    "color__gray-2": "#989898",
    "color__gray-3": "#d0d0d0",
    "color__gray-4": "#ededed",
    "color__gray-5": "#f5f5f5",
    // COLORS
    color__green: "#31b468",
    color__yellow: "#ffd166",
    color__blue: "#118ab2",
    color__pink: "#ef476f",
    color__turquoise: "#50c9ce",
    "color__turquoise-light": "#95DFE2",
    "color__turquoise-dark": "#2F797C",
    "color__turquoise-dark-2": "#183C3E",
    color__purple: "#9883e5",
    "color__purple-light": "#C1B5EF",
    "color__purple-dark": "#63539F",
    "color__purple-dark-2": "#3C2F6A",
    "color__blue-light": "#72a1e5",
    "color__blue-light-light": "#AAC7EF",
    "color__blue-light-dark": "#476998",
    "color__blue-light-dark-2": "#273E60",
    "color__green-light": "#19c587",
    color__red: "#f75555",
    color__orange: "#ff8b66",
    color__beige: '#E0D9D1',
    "color__grad-0": "#fd7f6f",
    "color__grad-0-light": "#FEB2A8",
    "color__grad-0-dark": "#B25042",
    "color__grad-0-dark-2": "#672117",
    "color__grad-1": "#7eb0d5",
    "color__grad-1-light": "#B1CFE6",
    "color__grad-1-dark": "#50738E",
    "color__grad-1-dark-2": "#223646",
    "color__grad-2": "#b2e061",
    "color__grad-2-light": "#D1ECA0",
    "color__grad-2-dark": "#718F3C",
    "color__grad-2-dark-2": "#405220",
    "color__grad-3": "#bd7ebe",
    "color__grad-3-light": "#D7B2D8",
    "color__grad-3-dark": "#774E77",
    "color__grad-3-dark-2": "#422B42",
    "color__grad-4": "#ffb55a",
    "color__grad-4-light": "#FFD39C",
    "color__grad-4-dark": "#AD7736",
    "color__grad-4-dark-2": "#6F491B",
    "color__grad-5": "#ffee65",
    "color__grad-5-light": "#FFF4A3",
    "color__grad-5-dark": "#B1A33D",
    "color__grad-5-dark-2": "#776C1E",
    "color__grad-6": "#beb9db",
    "color__grad-6-light": "#D8D5E9",
    "color__grad-6-dark": "#837EA0",
    "color__grad-6-dark-2": "#565173",
    "color__grad-7": "#fdcce5",
    "color__grad-7-light": "#FEE0EF",
    "color__grad-7-dark": "#D67DAA",
    "color__grad-7-dark-2": "#B8417E",
    "color__grad-8": "#8bd3c7",
    "color__grad-8-light": "#B9E5DD",
    "color__grad-8-dark": "#598E85",
    "color__grad-8-dark-2": "#345B54"
}

colors.color__primary = colors.color__green;
colors.color__ok = colors['color__green-light'];
colors.color__warning = colors.color__orange;
colors.color__danger = colors.color__red;

export const colorsArray = [
    "grad-0",
    "grad-1",
    "grad-2",
    "grad-3",
    "grad-4",
    "grad-5",
    "grad-6",
    "grad-7",
    "grad-8"
];

export default colors;