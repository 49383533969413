import React from "react";
import { PieArc, PieArcSeries, PieChart } from "reaviz";
import colors from "../../styles/colors";
import './Speedometer.scss';

const Speedometer = ({ score, goal, title, color, arcWidth, size, fontSize, pinBorder }) => (
    <div className="Speedometer" style={{ height: `${size * 0.85}rem` }}>
        <div className="Speedometer__pieChart" style={{ height: `${size}rem`, width: `${size}rem` }}>
            <div className="Speedometer__rest">
                <PieChart
                    data={[
                        { key: 'score', data: 0 },
                        { key: 'rest', data: 270 },
                        { key: 'transparent', data: 360 - 270 }
                    ]}
                    series={
                        <PieArcSeries
                            doughnut={true}
                            label={null}
                            padRadius={150}
                            padAngle={0.02}
                            arcWidth={arcWidth || 0.35}
                            cornerRadius={3}
                            colorScheme={(data, index) => {
                                if (data.key === 'score') {
                                    return color || colors["color__gray-1"];
                                }
                                return (data.key === 'transparent') ? 'transparent' : colors["color__gray-1"];
                            }}
                            arc={
                                <PieArc
                                    tooltip={false}
                                />
                            }
                        />
                    }
                />
            </div>
            <PieChart
                className="Speedometer__score"
                data={[
                    { key: 'score', data: Math.round(score) / 100 * 270 },
                    { key: 'rest', data: 0 },
                    { key: 'transparent', data: 360 - (Math.round(score) / 100 * 270) }
                ]}
                series={
                    <PieArcSeries
                        doughnut={true}
                        label={null}
                        padRadius={150}
                        padAngle={0.02}
                        arcWidth={arcWidth || 0.35}
                        cornerRadius={3}
                        colorScheme={(data, index) => {
                            if (data.key === 'score') {
                                return color || colors["color__gray-1"];
                            }
                            return (data.key === 'transparent') ? 'rgba(0, 0, 0, 0)' : colors["color__gray-1"];
                        }}
                        arc={
                            <PieArc
                                tooltip={false}
                            />
                        }
                    />
                }
            />
            {goal && <div className="Speedometer__goal" style={{ transform: `rotate(${Math.round(goal) / 100 * 270 + 225}deg)` }}><div className="Speedometer__goal--line"><span className="Speedometer__goal--number" style={{ transform: `translateX(-50%) rotate(${-(Math.round(goal) / 100 * 270 + 225)}deg)` }}>{Math.round(goal)}</span></div></div>}
            <div className={`Speedometer__pinWrapper ${pinBorder ? 'Speedometer__pinWrapper--background' : ''}`} style={{ height: `${size}rem`, width: `${size}rem`, transform: `rotate(-(${Math.round(goal) / 100 * 270 + 225})deg)` }}>
                <div className={`Speedometer__pin ${fontSize ? `Speedometer__pin--${fontSize}` : ''}`}>
                    <div style={{ height: `${size * 0.35}rem`, width: `${size * 0.35}rem` }}>{Math.round(score)}</div>
                </div>
            </div>
        </div>
        <div>
            <div className={`Speedometer__title ${fontSize === 'small' ? 'Speedometer__title--small' : ''}`} style={{ width: `${size}rem` }}>
                {title}
            </div>
        </div>
    </div>
)

export default Speedometer;