import React, { useEffect, useRef, useState } from "react";
import Logo from "../../Logo/Logo";
import './Preview.scss';

const Preview = ({ title, intro }) => {
    const ref = useRef();
    const defaultHeight = 624;
    const defaultWidth = 1024;
    const [height, setHeight] = useState(0);
    const [ratio, setRatio] = useState(0);

    useEffect(() => {
        const elWidth = ref.current.clientWidth;
        const newRatio = elWidth / defaultWidth;
        setRatio(newRatio);
        setHeight(newRatio * defaultHeight);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    const handleResize = () => {
        const elWidth = ref.current.clientWidth;
        const newRatio = elWidth / defaultWidth;
        setRatio(newRatio);
        setHeight(newRatio * defaultHeight);
    };

    return (
        <div className="Preview" >
            <div className="Preview__wrapper" ref={ref} style={{ height }}>
                <div className="Preview__innerWrapper" style={{ transform: `scale(${ratio})` }}>
                    <div className="Preview__browser">
                        <div className="Preview__browser--arrow"></div>
                        <div className="Preview__browser--http"></div>
                        <div className="Preview__browser--menu"></div>
                    </div>
                    <div className="Preview__logo">
                        <Logo />
                    </div>
                    <div className="Preview__background"></div>
                    <div className="Preview__content" style={{}}>
                        <div className="Preview__title" dangerouslySetInnerHTML={{ __html: title }}></div>
                        <div className="Preview__text" dangerouslySetInnerHTML={{ __html: intro }}></div>
                        <div className="Preview__button">
                            Continue
                            <span>press <strong>Enter ↵</strong></span>
                        </div>
                    </div>
                </div>
            </div>
            <p className="Preview__description">Introduction Page – Preview</p>
        </div>
    )
}

export default Preview;