import React, { Fragment, useState } from "react";
import constants from "../../../constants";
import ButtonPlainText from "../../Button/ButtonPlainText";
import InputField from "../../InputField/InputField";
import ButtonAction from "../../Button/ButtonAction";
import "./CreateSurveySegmentation.scss";

const CreateSurveySegmentation = ({ markets, departments, alias, onChange: propsOnChange }) => {
    const [showAddMarket, setShowAddMarket] = useState(false);
    const [showAddDepartment, setShowAddDepartment] = useState(false);
    const [newMarket, setNewMarket] = useState("");
    const [newDepartment, setNewDepartment] = useState("");

    const inputField = (name, value, onChange, onSave, onCancel) => (
        <div className="CreateSurveySegmentation__field">
            <div className="CreateSurveySegmentation__field--input">
                <InputField name={name} backgroundColor="white" value={value} onChange={onChange} />
            </div>
            <div className="CreateSurveySegmentation__field--actions">
                <ButtonAction type="check" onClick={onSave} disabled={value === ''} />
                <ButtonAction type="cancel--small" onClick={onCancel} />
            </div>
        </div>
    );

    const savedField = (item, onEdit, onDelete) => (
        <div className="CreateSurveySegmentation__field">
            <p className="CreateSurveySegmentation__field--label">
                <span>{item.name}</span>
                {item.recipients && <span className="CreateSurveySegmentation__text--small">{item.recipients.length || 0} recipients</span>}
            </p>
            <div className="CreateSurveySegmentation__field--actions">
                <ButtonAction type="edit" onClick={onEdit} />
                <ButtonAction type="delete--small" onClick={onDelete} />
            </div>
        </div>
    );

    const aliasGroup = (id, name, thisAlias) => (
        <div className="CreateSurveySegmentation__groups--row">
            <p className="CreateSurveySegmentation__groups--column">{name}</p>
            {!thisAlias.editMode && (
                <div className="CreateSurveySegmentation__groups--column">{thisAlias.alias ?
                    savedField(
                        { ...thisAlias, name: thisAlias.alias },
                        () => propsOnChange('change', 'alias', { ...alias, [id]: { ...thisAlias, aliasTemp: thisAlias.alias, editMode: true } }),
                        () => propsOnChange('change', 'alias', { ...alias, [id]: { ...thisAlias, alias: undefined, aliasTemp: undefined } })
                    ) : <ButtonPlainText text="Add alias" onClick={() => propsOnChange('change', 'alias', { ...alias, [id]: { ...thisAlias, editMode: true } })} />}
                </div>
            )}
            {thisAlias.editMode && (
                <div className="CreateSurveySegmentation__groups--column">{
                    inputField(
                        'executive',
                        thisAlias.aliasTemp,
                        value => propsOnChange('change', 'alias', { ...alias, [id]: { ...thisAlias, aliasTemp: value } }),
                        () => propsOnChange('change', 'alias', { ...alias, [id]: { ...thisAlias, alias: thisAlias.aliasTemp, editMode: false } }),
                        () => propsOnChange('change', 'alias', { ...alias, [id]: { ...thisAlias, aliasTemp: thisAlias.alias, editMode: false } }))
                }
                </div>
            )}
        </div>
    );

    const unitGroup = (id, name, unit, show, newItem, setNewItem, setShow) => {
        return (
            <>
                {unit.map((item, index) => (
                    <Fragment key={item.id}>
                        {!item.editMode && savedField(
                            item,
                            () => propsOnChange('change', name, { ...item, editMode: true, nameTemp: item.name }, index),
                            () => propsOnChange('delete', name, null, index)
                        )}
                        {!!item.editMode && inputField(
                            id,
                            item.nameTemp,
                            value => propsOnChange('change', name, { ...item, nameTemp: value }, index),
                            () => propsOnChange('save', name, { ...item, name: item.nameTemp, editMode: false }, index),
                            () => propsOnChange('cancel', name, { ...item, editMode: false }, index)
                        )}
                    </Fragment>
                ))}
                {show && <div className="CreateSurveySegmentation__units--column">{
                    inputField(id, newItem, value => setNewItem(value), () => {
                        propsOnChange('change', name, { id: newItem, name: newItem }, unit.length);
                        setNewItem("");
                    }, () => setShow(false))}</div>
                }
                {!show && <ButtonPlainText text={`Add ${name}`} onClick={() => setShow(true)} />}
            </>
        )
    }



    return (
        <div>
            <p>All responses will be categorised according to the respondents' organisational position and belonging. This is critical in order to analyse alignment and compare different groups. Please make the selections relevant to your organisation below.</p>
            <h3>Position</h3>
            <p>You can give the default groups aliases in order to better fit the terminology used in your organisation. This is optional.</p>
            <div className="CreateSurveySegmentation__groups">
                <div className="CreateSurveySegmentation__groups--row">
                    <h4 className="CreateSurveySegmentation__groups--column">Group</h4>
                    <h4 className="CreateSurveySegmentation__groups--column">Alias</h4>
                </div>
                {aliasGroup('executive', constants.roles.executive.name, alias.executive)}
                {aliasGroup('senior', constants.roles.senior.name, alias.senior)}
                {aliasGroup('operational', constants.roles.operational.name, alias.operational)}
            </div>
            <h3>Unit</h3>
            <p>Please select how you would like to segment organisational belonging below. Departments become subunits of Markets. You need to add at least <strong>one</strong> Market.</p>
            <div className="CreateSurveySegmentation__units">
                <div className="CreateSurveySegmentation__units--column">
                    <h4>Markets (Level 1)</h4>
                    {unitGroup('market', 'markets', markets, showAddMarket, newMarket, setNewMarket, setShowAddMarket)}
                </div>
                <div className="CreateSurveySegmentation__units--column">
                    <h4>Departments (Level 2)</h4>
                    {unitGroup('department', 'departments', departments, showAddDepartment, newDepartment, setNewDepartment, setShowAddDepartment)}
                </div>
            </div>
        </div>
    )
}

export default CreateSurveySegmentation;