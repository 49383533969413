import React, { Fragment } from "react";
import ProgressBar from "../ProgressBar/ProgressBar";
import BarListYlines from "./BarListYlines";
import './BarList.scss';

const BarList = ({ data, goal, color, prefix }) => (
    <div className="BarList">
        <BarListYlines lines={[25, 50, 75, 100]} />
        <div className="BarList__list">
            <ProgressBar progress={Math.round(data.score)} color={color} secondary={goal} withNumber={true} height={'2rem'} hasBackground={true} />
            {goal && <div className="BarList__target"><span className="BarList__target--line" style={{ left: `calc(${goal}% - 2px)` }}></span><span className="BarList__target--number" style={{ left: `calc(${goal}% - 1px)` }}>{goal}</span></div>}
            <div className="BarList__sublist">
                {Object.values(data.children).map((child, index) => (
                    <Fragment key={child.id}>
                        <p className="BarList__title">{prefix}{index + 1}: {child.title}</p>
                        <div className="BarList__bars">
                            {child.data.map(d => <ProgressBar key={d.id} {...d} />)}
                        </div>
                    </Fragment>
                ))}
            </div>
        </div>
    </div>
)

export default BarList;