import React, { useEffect, useRef, useState } from "react";
import ButtonAction from "../Button/ButtonAction";
import GoalContainer from "../../container/GoalContainer";
import GoalSetting from "../GoalSetting/GoalSetting";
import Popup from "../Popup/Popup";
import useOutsideClick from "../../hooks/useOutsideClick";
import './Sidebar.scss';

const Sidebar = ({ values, active, handleClose, handleOnSave: propsHandleOnSave }) => {
    const ref = useRef();
    const popupRef = useRef();
    const [open, setOpen] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [currentValues, setCurrentValues] = useState(null);
    const [resetGoal, setResetGoal] = useState(false);
    const [clickedCancel, setClickedCancel] = useState(false);

    useEffect(() => {
        setOpen(active);
        setResetGoal(false);
        setOpenPopup(false);
    }, [active]);

    const handleOnClose = () => {
        if (!currentValues) {
            handleClose();
        } else {
            const oldValues = Object.entries(currentValues.oldGoal);
            let changed = false;
            for (let i = 0; i < oldValues.length; i++) {
                const [key, value] = oldValues[i];

                if (Number.isInteger(value) && currentValues.newGoal[key] !== Math.round(value)) {
                    setOpenPopup(true);
                    changed = true;
                    continue;
                }
            }
            if (!changed) handleClose();
        }
        setClickedCancel(false);
    }

    const handleOnSave = currentGoal => {
        setCurrentValues({
            oldGoal: currentGoal,
            newGoal: currentGoal
        });
        propsHandleOnSave(currentGoal);
    }

    useOutsideClick(ref, () => {
        if (open && !openPopup && !clickedCancel) handleOnClose();
        setClickedCancel(false);
    });

    return (
        <div className="Sidebar">
            <div className={`Sidebar__background ${active === null ? '' : (active) ? 'Sidebar__background--open' : 'Sidebar__background--close'}`}>
            </div>
            <div ref={ref} className={`Sidebar__wrapper ${active === null ? '' : (active) ? 'Sidebar__wrapper--open' : 'Sidebar__wrapper--close'}`}>
                <div className="Sidebar__header">
                    <div>
                        <h2>Company targets</h2>
                        <ButtonAction type="cross" onClick={handleOnClose} />
                    </div>
                </div>
                <div className="Sidebar__container">
                    <p className="Sidebar__paragraph">Here you can set the targets for the company. The presented resultats are from last viewed survey.</p>
                    <GoalContainer>
                        <GoalSetting resetGoal={resetGoal} values={values} onSave={handleOnSave} onChange={(oldGoal, newGoal) => setCurrentValues({ oldGoal, newGoal })} />
                    </GoalContainer>
                </div>
            </div>
            <div ref={popupRef}>
                {openPopup && <Popup
                    title="You haven't saved your changes"
                    color="white"
                    fields={[
                        {
                            value: "Are you sure you want to close? Your changes will get lost."
                        }
                    ]}
                    submitText="Close"
                    onSubmit={() => {
                        setCurrentValues({
                            ...currentValues,
                            newGoal: currentValues.oldGoal
                        })
                        handleClose();
                        setResetGoal(true);
                        setOpenPopup(false);
                    }}
                    onCancel={() => {
                        setOpenPopup(false);
                        setClickedCancel(true);
                    }}
                />}
            </div>
        </div>
    );
}

export default Sidebar;